export default [
    {
        area: 'Bottom of the Well',
        locations: [
            'Bottom of the Well Back Left Bombable Chest',
            'Bottom of the Well Center Skulltula Chest',
            'Bottom of the Well Compass Chest',
            'Bottom of the Well Fire Keese Chest',
            'Bottom of the Well Freestanding Key',
            'Bottom of the Well Front Center Bombable Chest',
            'Bottom of the Well Front Left Fake Wall Chest',
            'Bottom of the Well GS East Inner Room',
            'Bottom of the Well GS Like Like Cage',
            'Bottom of the Well GS West Inner Room',
            'Bottom of the Well Invisible Chest',
            'Bottom of the Well Lens of Truth Chest',
            'Bottom of the Well Like Like Chest',
            'Bottom of the Well Map Chest',
            'Bottom of the Well Right Bottom Fake Wall Chest',
            'Bottom of the Well Underwater Front Chest',
            'Bottom of the Well Underwater Left Chest',
        ],
    },
    {
        area: 'Desert Colossus',
        locations: [
            'Colossus Deku Scrub Grotto Front',
            'Colossus Deku Scrub Grotto Rear',
            'Colossus Freestanding PoH',
            'Colossus GS Bean Patch',
            'Colossus GS Hill',
            'Colossus GS Tree',
            'Colossus Great Fairy Reward',
            'Sheik at Colossus',

            'Colossus Gossip Stone',
        ],
    },
    {
        area: 'Death Mountain Crater',
        locations: [
            'DMC Deku Scrub',
            'DMC Deku Scrub Grotto Center',
            'DMC Deku Scrub Grotto Left',
            'DMC Deku Scrub Grotto Right',
            'DMC GS Bean Patch',
            'DMC GS Crate',
            'DMC Great Fairy Reward',
            'DMC Upper Grotto Chest',
            'DMC Volcano Freestanding PoH',
            'DMC Wall Freestanding PoH',
            'Sheik in Crater',

            'DMC Gossip Stone',
            'DMC Upper Grotto Gossip Stone',
        ],
    },
    {
        area: 'Death Mountain Trail',
        locations: [
            'DMT Chest',
            'DMT Cow Grotto Cow',
            'DMT Freestanding PoH',
            'DMT GS Above Dodongos Cavern',
            'DMT GS Bean Patch',
            'DMT GS Falling Rocks Path',
            'DMT GS Near Kak',
            'DMT Great Fairy Reward',
            'DMT Storms Grotto Chest',
            'DMT Trade Broken Sword',
            'DMT Trade Claim Check',
            'DMT Trade Eyedrops',

            'DMT Gossip Stone',
            'DMT Storms Grotto Gossip Stone',
            'Fairy Gossip Stone',
        ],
    },
    {
        area: 'Deku Tree',
        locations: [
            'Deku Tree Basement Chest',
            'Deku Tree Compass Chest',
            'Deku Tree Compass Room Side Chest',
            'Deku Tree GS Basement Back Room',
            'Deku Tree GS Basement Gate',
            'Deku Tree GS Basement Vines',
            'Deku Tree GS Compass Room',
            'Deku Tree Map Chest',
            'Deku Tree Queen Gohma Heart Container',
            'Deku Tree Slingshot Chest',
            'Deku Tree Slingshot Room Side Chest',
            'Queen Gohma',
        ],
    },
    {
        area: 'Dodongos Cavern',
        locations: [
            'King Dodongo',
            'Dodongos Cavern Bomb Bag Chest',
            'Dodongos Cavern Bomb Flower Platform Chest',
            'Dodongos Cavern Boss Room Chest',
            'Dodongos Cavern Compass Chest',
            'Dodongos Cavern Deku Scrub Lobby',
            'Dodongos Cavern Deku Scrub Near Bomb Bag Left',
            'Dodongos Cavern Deku Scrub Near Bomb Bag Right',
            'Dodongos Cavern Deku Scrub Side Room Near Dodongos',
            'Dodongos Cavern End Of Bridge Chest',
            'Dodongos Cavern GS Alcove Above Stairs',
            'Dodongos Cavern GS Back Room',
            'Dodongos Cavern GS Scarecrow',
            'Dodongos Cavern GS Side Room Near Lower Lizalfos',
            'Dodongos Cavern GS Vines Above Stairs',
            'Dodongos Cavern King Dodongo Heart Container',
            'Dodongos Cavern Map Chest',

            'Dodongo\'s Cavern Gossip Stone',
        ],
    },
    {
        area: 'Fire Temple',
        locations: [
            'Fire Temple Big Lava Room Blocked Door Chest',
            'Fire Temple Big Lava Room Lower Open Door Chest',
            'Fire Temple Boss Key Chest',
            'Fire Temple Boulder Maze Lower Chest',
            'Fire Temple Boulder Maze Shortcut Chest',
            'Fire Temple Boulder Maze Side Room Chest',
            'Fire Temple Boulder Maze Upper Chest',
            'Fire Temple Compass Chest',
            'Fire Temple Flare Dancer Chest',
            'Fire Temple GS Boss Key Loop',
            'Fire Temple GS Boulder Maze',
            'Fire Temple GS Scarecrow Climb',
            'Fire Temple GS Scarecrow Top',
            'Fire Temple GS Song of Time Room',
            'Fire Temple Highest Goron Chest',
            'Fire Temple Map Chest',
            'Fire Temple Megaton Hammer Chest',
            'Fire Temple Near Boss Chest',
            'Fire Temple Scarecrow Chest',
            'Fire Temple Volvagia Heart Container',
            'Volvagia',
        ],
    },
    {
        area: 'Forest Temple',
        locations: [
            'Forest Temple Basement Chest',
            'Forest Temple Blue Poe Chest',
            'Forest Temple Boss Key Chest',
            'Forest Temple Bow Chest',
            'Forest Temple Eye Switch Chest',
            'Forest Temple Falling Ceiling Room Chest',
            'Forest Temple First Room Chest',
            'Forest Temple First Stalfos Chest',
            'Forest Temple Floormaster Chest',
            'Forest Temple GS Basement',
            'Forest Temple GS First Room',
            'Forest Temple GS Level Island Courtyard',
            'Forest Temple GS Lobby',
            'Forest Temple GS Raised Island Courtyard',
            'Forest Temple Map Chest',
            'Forest Temple Phantom Ganon Heart Container',
            'Forest Temple Raised Island Courtyard Chest',
            'Forest Temple Red Poe Chest',
            'Forest Temple Well Chest',
            'Phantom Ganon',
        ],
    },
    {
        area: 'Goron City',
        locations: [
            'GC Darunias Joy',
            'GC Deku Scrub Grotto Center',
            'GC Deku Scrub Grotto Left',
            'GC Deku Scrub Grotto Right',
            'GC GS Boulder Maze',
            'GC GS Center Platform',
            'GC Maze Center Chest',
            'GC Maze Left Chest',
            'GC Maze Right Chest',
            'GC Medigoron',
            'GC Pot Freestanding PoH',
            'GC Rolling Goron as Adult',
            'GC Rolling Goron as Child',
            'GC Shop Item 1',
            'GC Shop Item 2',
            'GC Shop Item 3',
            'GC Shop Item 4',
            'GC Shop Item 5',
            'GC Shop Item 6',
            'GC Shop Item 7',
            'GC Shop Item 8',

            'GC Maze Gossip Stone',
            'GC Medigoron Gossip Stone',
        ],
    },
    {
        area: 'Gerudo Fortress',
        locations: [
            'GF Chest',
            'GF GS Archery Range',
            'GF GS Top Floor',
            'GF Gerudo Membership Card',
            'GF HBA 1000 Points',
            'GF HBA 1500 Points',
            'GF North F1 Carpenter',
            'GF North F2 Carpenter',
            'GF South F1 Carpenter',
            'GF South F2 Carpenter',
        ],
    },
    {
        area: 'Gerudo Valley',
        locations: [
            'GV Chest',
            'GV Cow',
            'GV Crate Freestanding PoH',
            'GV Deku Scrub Grotto Front',
            'GV Deku Scrub Grotto Rear',
            'GV GS Bean Patch',
            'GV GS Behind Tent',
            'GV GS Pillar',
            'GV GS Small Bridge',
            'GV Trade Saw',
            'GV Waterfall Freestanding PoH',

            'GV Gossip Stone',
        ],
    },
    {
        area: 'Graveyard',
        locations: [
            'GY Composers Grave Chest',
            'GY Dampe Gravedigging Tour',
            'GY Dampe Race Freestanding PoH',
            'GY Freestanding PoH',
            'GY Heart Piece Grave Chest',
            'GY Hookshot Chest',
            'GY Shield Grave Chest',
            'Graveyard GS Bean Patch',
            'Graveyard GS Wall',
            'Song from Composers Grave',

            'GY Gossip Stone',
            'GY Gravekeepers Hut',
        ],
    },
    {
        area: 'Ganon\'s Castle',
        locations: [
            'Ganon',
            'Ganondorf',
            'Ganon\'s Castle Deku Scrub Center-Left',
            'Ganon\'s Castle Deku Scrub Center-Right',
            'Ganon\'s Castle Deku Scrub Left',
            'Ganon\'s Castle Deku Scrub Right',
            'Ganon\'s Castle Forest Trial Chest',
            'Ganon\'s Castle Light Trial First Left Chest',
            'Ganon\'s Castle Light Trial First Right Chest',
            'Ganon\'s Castle Light Trial Invisible Enemies Chest',
            'Ganon\'s Castle Light Trial Lullaby Chest',
            'Ganon\'s Castle Light Trial Second Left Chest',
            'Ganon\'s Castle Light Trial Second Right Chest',
            'Ganon\'s Castle Light Trial Third Left Chest',
            'Ganon\'s Castle Light Trial Third Right Chest',
            'Ganon\'s Castle Shadow Trial Front Chest',
            'Ganon\'s Castle Shadow Trial Golden Gauntlets Chest',
            'Ganon\'s Castle Spirit Trial Crystal Switch Chest',
            'Ganon\'s Castle Spirit Trial Invisible Chest',
            'Ganon\'s Castle Water Trial Left Chest',
            'Ganon\'s Castle Water Trial Right Chest',
            'Ganon\'s Tower Boss Key Chest',
        ],
    },
    {
        area: 'Gerudo Training Grounds',
        locations: [
            'Gerudo Training Grounds Beamos Chest',
            'Gerudo Training Grounds Before Heavy Block Chest',
            'Gerudo Training Grounds Eye Statue Chest',
            'Gerudo Training Grounds Freestanding Key',
            'Gerudo Training Grounds Hammer Room Clear Chest',
            'Gerudo Training Grounds Hammer Room Switch Chest',
            'Gerudo Training Grounds Heavy Block First Chest',
            'Gerudo Training Grounds Heavy Block Fourth Chest',
            'Gerudo Training Grounds Heavy Block Second Chest',
            'Gerudo Training Grounds Heavy Block Third Chest',
            'Gerudo Training Grounds Hidden Ceiling Chest',
            'Gerudo Training Grounds Lobby Left Chest',
            'Gerudo Training Grounds Lobby Right Chest',
            'Gerudo Training Grounds Maze Path Final Chest',
            'Gerudo Training Grounds Maze Path First Chest',
            'Gerudo Training Grounds Maze Path Second Chest',
            'Gerudo Training Grounds Maze Path Third Chest',
            'Gerudo Training Grounds Maze Right Central Chest',
            'Gerudo Training Grounds Maze Right Side Chest',
            'Gerudo Training Grounds Near Scarecrow Chest',
            'Gerudo Training Grounds Stalfos Chest',
            'Gerudo Training Grounds Underwater Silver Rupee Chest',
        ],
    },
    {
        area: 'Hyrule Castle',
        locations: [
            'HC GS Storms Grotto',
            'HC GS Tree',
            'HC Great Fairy Reward',
            'HC Malon Egg',
            'HC Zeldas Letter',
            'OGC GS',
            'OGC Great Fairy Reward',
            'Song from Impa',

            'HC Malon Gossip Stone',
            'HC Rock Wall Gossip Stone',
            'HC Storms Grotto Gossip Stone',
        ],
    },
    {
        area: 'Hyrule Field',
        locations: [
            'HF Cow Grotto Cow',
            'HF Deku Scrub Grotto',
            'HF GS Cow Grotto',
            'HF GS Near Kak Grotto',
            'HF Near Market Grotto Chest',
            'HF Ocarina of Time Item',
            'HF Open Grotto Chest',
            'HF Southeast Grotto Chest',
            'HF Tektite Grotto Freestanding PoH',
            'Song from Ocarina of Time',

            'HF Cow Grotto Gossip Stone',
            'HF Near Market Gossip Stone',
            'HF Open Grotto Gossip Stone',
            'HF Southeast Gossip Stone',
        ],
    },
    {
        area: 'Ice Cavern',
        locations: [
            'Ice Cavern Compass Chest',
            'Ice Cavern Freestanding PoH',
            'Ice Cavern GS Heart Piece Room',
            'Ice Cavern GS Push Block Room',
            'Ice Cavern GS Spinning Scythe Room',
            'Ice Cavern Iron Boots Chest',
            'Ice Cavern Map Chest',
            'Sheik in Ice Cavern',
        ],
    },
    {
        area: 'Jabu Jabus Belly',
        locations: [
            'Barinade',
            'Jabu Jabus Belly Barinade Heart Container',
            'Jabu Jabus Belly Boomerang Chest',
            'Jabu Jabus Belly Compass Chest',
            'Jabu Jabus Belly Deku Scrub',
            'Jabu Jabus Belly GS Lobby Basement Lower',
            'Jabu Jabus Belly GS Lobby Basement Upper',
            'Jabu Jabus Belly GS Near Boss',
            'Jabu Jabus Belly GS Water Switch Room',
            'Jabu Jabus Belly Map Chest',
        ],
    },
    {
        area: 'Kokiri Forest',
        locations: [
            'KF GS Bean Patch',
            'KF GS House of Twins',
            'KF GS Know It All House',
            'KF Kokiri Sword Chest',
            'KF Links House Cow',
            'KF Mido Bottom Left Chest',
            'KF Mido Bottom Right Chest',
            'KF Mido Top Left Chest',
            'KF Mido Top Right Chest',
            'KF Shop Item 1',
            'KF Shop Item 2',
            'KF Shop Item 3',
            'KF Shop Item 4',
            'KF Shop Item 5',
            'KF Shop Item 6',
            'KF Shop Item 7',
            'KF Shop Item 8',
            'KF Storms Grotto Chest',

            'KF Deku Tree Left Gossip Stone',
            'KF Deku Tree Right Gossip Stone',
            'KF Gossip Stone',
            'KF Storms Gossip Stone',
        ],
    },
    {
        area: 'Kakariko',
        locations: [
            'Kak 10 Gold Skulltula Reward',
            'Kak 20 Gold Skulltula Reward',
            'Kak 30 Gold Skulltula Reward',
            'Kak 40 Gold Skulltula Reward',
            'Kak 50 Gold Skulltula Reward',
            'Kak 100 Gold Skulltula Reward',
            'Kak Anju as Adult',
            'Kak Anju as Child',
            'Kak Bazaar Item 1',
            'Kak Bazaar Item 2',
            'Kak Bazaar Item 3',
            'Kak Bazaar Item 4',
            'Kak Bazaar Item 5',
            'Kak Bazaar Item 6',
            'Kak Bazaar Item 7',
            'Kak Bazaar Item 8',
            'Kak GS Above Impas House',
            'Kak GS Guards House',
            'Kak GS House Under Construction',
            'Kak GS Skulltula House',
            'Kak GS Tree',
            'Kak GS Watchtower',
            'Kak Impas House Cow',
            'Kak Impas House Freestanding PoH',
            'Kak Man on Roof',
            'Kak Open Grotto Chest',
            'Kak Potion Shop Item 1',
            'Kak Potion Shop Item 2',
            'Kak Potion Shop Item 3',
            'Kak Potion Shop Item 4',
            'Kak Potion Shop Item 5',
            'Kak Potion Shop Item 6',
            'Kak Potion Shop Item 7',
            'Kak Potion Shop Item 8',
            'Kak Redead Grotto Chest',
            'Kak Shooting Gallery Reward',
            'Kak Trade Odd Mushroom',
            'Kak Trade Pocket Cucco',
            'Kak Windmill Freestanding PoH',
            'Sheik in Kakariko',
            'Song from Windmill',

            'Kak Open Grotto Gossip Stone',
        ],
    },
    {
        area: 'Lake Hylia',
        locations: [
            'LH Adult Fishing',
            'LH Child Fishing',
            'LH Deku Scrub Grotto Center',
            'LH Deku Scrub Grotto Left',
            'LH Deku Scrub Grotto Right',
            'LH Freestanding PoH',
            'LH GS Bean Patch',
            'LH GS Lab Crate',
            'LH GS Lab Wall',
            'LH GS Small Island',
            'LH GS Tree',
            'LH Lab Dive',
            'LH Lab Trade Eyeball Frog',
            'LH Sun',
            'LH Underwater Item',

            'LH Lab Gossip Stone',
            'LH Southeast Gossip Stone',
            'LH Southwest Gossip Stone',
        ],
    },
    {
        area: 'Lon Lon Ranch',
        locations: [
            'LLR Deku Scrub Grotto Center',
            'LLR Deku Scrub Grotto Left',
            'LLR Deku Scrub Grotto Right',
            'LLR Freestanding PoH',
            'LLR GS Back Wall',
            'LLR GS House Window',
            'LLR GS Rain Shed',
            'LLR GS Tree',
            'LLR Stables Left Cow',
            'LLR Stables Right Cow',
            'LLR Talons Chickens',
            'LLR Tower Left Cow',
            'LLR Tower Right Cow',
            'Song from Malon',
        ],
    },
    {
        area: 'Lost Woods',
        locations: [
            'Deku Theater Mask of Truth',
            'Deku Theater Skull Mask',
            'LW Deku Scrub Grotto Front',
            'LW Deku Scrub Grotto Rear',
            'LW Deku Scrub Near Bridge',
            'LW Deku Scrub Near Deku Theater Left',
            'LW Deku Scrub Near Deku Theater Right',
            'LW GS Above Theater',
            'LW GS Bean Patch Near Bridge',
            'LW GS Bean Patch Near Theater',
            'LW Gift From Saria',
            'LW Near Shortcuts Grotto Chest',
            'LW Ocarina Memory Game',
            'LW Skull Kid',
            'LW Target in Woods',
            'LW Trade Cojiro',
            'LW Trade Odd Potion',

            'LW Gossip Stone',
            'LW Near Shortcuts Gossip Stone',
        ],
    },
    {
        area: 'Market',
        locations: [
            'MK 10 Big Poes',
            'MK Bazaar Item 1',
            'MK Bazaar Item 2',
            'MK Bazaar Item 3',
            'MK Bazaar Item 4',
            'MK Bazaar Item 5',
            'MK Bazaar Item 6',
            'MK Bazaar Item 7',
            'MK Bazaar Item 8',
            'MK Bombchu Bowling Bombchus',
            'MK Bombchu Bowling First Prize',
            'MK Bombchu Bowling Second Prize',
            'MK Bombchu Shop Item 1',
            'MK Bombchu Shop Item 2',
            'MK Bombchu Shop Item 3',
            'MK Bombchu Shop Item 4',
            'MK Bombchu Shop Item 5',
            'MK Bombchu Shop Item 6',
            'MK Bombchu Shop Item 7',
            'MK Bombchu Shop Item 8',
            'MK Chest Game Fifth Room Chest',
            'MK Chest Game First Room Chest',
            'MK Chest Game Fourth Room Chest',
            'MK Chest Game Second Room Chest',
            'MK Chest Game Third Room Chest',
            'MK Lost Dog',
            'MK Potion Shop Item 1',
            'MK Potion Shop Item 2',
            'MK Potion Shop Item 3',
            'MK Potion Shop Item 4',
            'MK Potion Shop Item 5',
            'MK Potion Shop Item 6',
            'MK Potion Shop Item 7',
            'MK Potion Shop Item 8',
            'MK Shooting Gallery',
            'MK Treasure Chest Game Reward',
            'Market GS Guard House',

            'ToT Left Center Gossip Stone',
            'ToT Left Gossip Stone',
            'ToT Right Center Gossip Stone',
            'ToT Right Gossip Stone',
            'MK Chest Game Owner',
        ],
    },
    {
        area: 'Temple of Time',
        locations: [
            'Sheik at Temple',
            'ToT Light Arrow Cutscene',
            'ToT Master Sword',

            'ToT Child Altar',
            'ToT Adult Altar',
        ],
    },
    {
        area: 'Sacred Forest Meadow',
        locations: [
            'SFM Deku Scrub Grotto Front',
            'SFM Deku Scrub Grotto Rear',
            'SFM GS',
            'SFM Wolfos Grotto Chest',
            'Sheik in Forest',
            'Song from Saria',

            'SFM Maze Lower Gossip Stone',
            'SFM Maze Upper Gossip Stone',
            'SFM Saria Gossip Stone',
        ],
    },
    {
        area: 'Shadow Temple',
        locations: [
            'Bongo Bongo',
            'Shadow Temple After Wind Enemy Chest',
            'Shadow Temple After Wind Hidden Chest',
            'Shadow Temple Bongo Bongo Heart Container',
            'Shadow Temple Boss Key Chest',
            'Shadow Temple Compass Chest',
            'Shadow Temple Early Silver Rupee Chest',
            'Shadow Temple Falling Spikes Lower Chest',
            'Shadow Temple Falling Spikes Switch Chest',
            'Shadow Temple Falling Spikes Upper Chest',
            'Shadow Temple Freestanding Key',
            'Shadow Temple GS Falling Spikes Room',
            'Shadow Temple GS Like Like Room',
            'Shadow Temple GS Near Ship',
            'Shadow Temple GS Single Giant Pot',
            'Shadow Temple GS Triple Giant Pot',
            'Shadow Temple Hover Boots Chest',
            'Shadow Temple Invisible Blades Invisible Chest',
            'Shadow Temple Invisible Blades Visible Chest',
            'Shadow Temple Invisible Floormaster Chest',
            'Shadow Temple Invisible Spikes Chest',
            'Shadow Temple Map Chest',
            'Shadow Temple Spike Walls Left Chest',
            'Shadow Temple Wind Hint Chest',
        ],
    },
    {
        area: 'Spirit Temple',
        locations: [
            'Twinrova',
            'Spirit Temple Boss Key Chest',
            'Spirit Temple Child Bridge Chest',
            'Spirit Temple Child Climb East Chest',
            'Spirit Temple Child Climb North Chest',
            'Spirit Temple Child Early Torches Chest',
            'Spirit Temple Compass Chest',
            'Spirit Temple Early Adult Right Chest',
            'Spirit Temple First Mirror Left Chest',
            'Spirit Temple First Mirror Right Chest',
            'Spirit Temple GS Boulder Room',
            'Spirit Temple GS Hall After Sun Block Room',
            'Spirit Temple GS Lobby',
            'Spirit Temple GS Metal Fence',
            'Spirit Temple GS Sun on Floor Room',
            'Spirit Temple Hallway Left Invisible Chest',
            'Spirit Temple Hallway Right Invisible Chest',
            'Spirit Temple Map Chest',
            'Spirit Temple Mirror Shield Chest',
            'Spirit Temple Near Four Armos Chest',
            'Spirit Temple Silver Gauntlets Chest',
            'Spirit Temple Statue Room Hand Chest',
            'Spirit Temple Statue Room Northeast Chest',
            'Spirit Temple Sun Block Room Chest',
            'Spirit Temple Topmost Chest',
            'Spirit Temple Twinrova Heart Container',
        ],
    },
    {
        area: 'Haunted Wasteland',
        locations: [
            'Wasteland Carpet Salesman',
            'Wasteland Chest',
            'Wasteland GS',
        ],
    },
    {
        area: 'Water Temple',
        locations: [
            'Morpha',
            'Water Temple Boss Key Chest',
            'Water Temple Central Bow Target Chest',
            'Water Temple Central Pillar Chest',
            'Water Temple Compass Chest',
            'Water Temple Cracked Wall Chest',
            'Water Temple Dragon Chest',
            'Water Temple GS Behind Gate',
            'Water Temple GS Central Pillar',
            'Water Temple GS Falling Platform Room',
            'Water Temple GS Near Boss Key Chest',
            'Water Temple GS River',
            'Water Temple Longshot Chest',
            'Water Temple Map Chest',
            'Water Temple Morpha Heart Container',
            'Water Temple River Chest',
            'Water Temple Torches Chest',
        ],
    },
    {
        area: 'Zora\'s Domain',
        locations: [
            'ZD Chest',
            'ZD Diving Minigame',
            'ZD GS Frozen Waterfall',
            'ZD King Zora Thawed',
            'ZD Shop Item 1',
            'ZD Shop Item 2',
            'ZD Shop Item 3',
            'ZD Shop Item 4',
            'ZD Shop Item 5',
            'ZD Shop Item 6',
            'ZD Shop Item 7',
            'ZD Shop Item 8',
            'ZD Trade Prescription',

            'ZD Gossip Stone',
        ],
    },
    {
        area: 'Zora\'s Fountain',
        locations: [
            'ZF Bottom Freestanding PoH',
            'ZF GS Above The Log',
            'ZF GS Hidden Cave',
            'ZF GS Tree',
            'ZF Great Fairy Reward',
            'ZF Iceberg Freestanding PoH',

            'Jabu Gossip Stone',
        ],
    },
    {
        area: 'Zora\'s River',
        locations: [
            'ZR Deku Scrub Grotto Front',
            'ZR Deku Scrub Grotto Rear',
            'ZR Frogs Epona\'s Song',
            'ZR Frogs Ocarina Game',
            'ZR Frogs Saria\'s Song',
            'ZR Frogs Song of Time',
            'ZR Frogs Sun\'s Song',
            'ZR Frogs Zelda\'s Lullaby',
            'ZR Frogs in the Rain',
            'ZR GS Above Bridge',
            'ZR GS Ladder',
            'ZR GS Near Raised Grottos',
            'ZR GS Tree',
            'ZR Magic Bean Salesman',
            'ZR Near Domain Freestanding PoH',
            'ZR Near Open Grotto Freestanding PoH',
            'ZR Open Grotto Chest',

            'ZR Near Domain Gossip Stone',
            'ZR Near Grottos Gossip Stone',
            'ZR Open Grotto Gossip Stone',
        ],
    },
].sort((a, b) => a.area.localeCompare(b.area))