<template>
    <v-tooltip bottom :open-delay="500">
        <template v-slot:activator="{ on }">
            <v-btn
                icon
                @click="download"
                v-on="on"
            >
                <v-icon>mdi-download</v-icon>
            </v-btn>
        </template>

        Download log
    </v-tooltip>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    name: "SpoiledDownloadButton",
    methods: {
        download() {
            let data = this.$store.getters['Log/logFile'];

            FileSaver.saveAs(
                new Blob([JSON.stringify(data.file, null, 4)], {type: 'application/json'}),
                data.id + '.json'
            );
        },
    },
}
</script>

<style scoped>

</style>