<template>
    <div
        @drop.prevent="dropFile"
        @dragover.prevent="dragOver(true)"
        @dragleave.prevent="dragOver(false)"
    >
        <slot/>
    </div>
</template>

<script>
export default {
    name: "SpoiledDropZone",
    methods: {
        dragOver(value) {
            this.$emit('over', value);
        },
        dropFile(e) {
            this.$emit('file', e.dataTransfer.files[0]);
            this.dragOver(false);
        },
    },
}
</script>

<style scoped>

</style>