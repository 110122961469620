<template>
    <v-app-bar app bottom dense class="bottom-bar-fix">
        <div class="left ml-3">
            <spoiled-changelog/>
        </div>

        <v-layout d-flex justify-center align-center>
            <slot/>
        </v-layout>

        <div class="right mr-3">
            <spoiled-to-top/>
        </div>
    </v-app-bar>
</template>

<script>
import SpoiledToTop from "@/components/base/buttons/SpoiledToTop.vue";
import SpoiledChangelog from "@/components/base/SpoiledChangelog";

export default {
    name: "SpoiledBottomBar",
    components: {SpoiledChangelog, SpoiledToTop},
}
</script>

<style scoped>
.left {
    position: absolute;
    left: 0;
}

.right {
    position: absolute;
    right: 0;
}

.bottom-bar-fix {
    top: auto !important;
}
</style>