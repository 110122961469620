<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "SpoiledTricksButton",
    data: function () {
        return {
            open: false,
            search: null,
        };
    },
    computed: {
        tricks() {
            return this.$store.getters['Log/tricks']
                .filter(t => !this.search || t.toLowerCase().includes(this.search.toLowerCase()));
        },
    },
})
</script>

<template>
    <v-menu
        offset-y
        :close-on-content-click="false"
        v-model="open"
    >
        <template v-slot:activator="{ on: onMenu }">
            <v-tooltip bottom :open-delay="500">
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                        icon
                        :disabled="!tricks.length"
                        v-on="{ ...onMenu, ...onTooltip}"
                        @click="open = true"
                    >
                        <v-icon>mdi-checkbox-marked-outline</v-icon>
                    </v-btn>
                </template>

                Enabled Tricks
            </v-tooltip>
        </template>

        <v-card>
            <v-toolbar
                flat
                dense
            >
                <v-text-field
                    solo
                    flat
                    hide-details
                    clearable
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    v-model="search"
                    class="transparent"
                />
            </v-toolbar>

            <v-sheet
                width="450px"
                max-height="500px"
                style="overflow-y: auto;"
            >
                <v-list
                    dense
                    v-if="!!tricks.length"
                >
                    <v-list-item
                        v-for="(item, index) in tricks"
                        :key="index"
                    >
                        <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                </v-list>

                <v-list
                    dense
                    v-else
                >
                    <v-list-item>
                        <v-list-item-title>No results</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-sheet>
        </v-card>
    </v-menu>
</template>

<style scoped>
/deep/ .v-toolbar__content {
    padding: 0;
}

.transparent /deep/ .v-input__slot {
    background: transparent !important;
}
</style>