<template>
    <v-card class="nocopy">
        <v-toolbar
            dense
            flat
            class="interactable"
            @click="setDone"
            :class="{ 'green darken-3': value.done }"
        >
            <b>{{ value.id }}</b>

            <v-spacer/>

            <div v-show="showPrices">
                <spoiled-rupee :amount="value.price" size="24"/>
            </div>
            <v-icon v-if="showTraps && value.trap">mdi-alert-outline</v-icon>
        </v-toolbar>

        <div class="px-4 py-3 text--secondary" :class="{ 'no-spoilers': !showSpoilers }">
            {{ value.item }}
        </div>
    </v-card>
</template>

<script>
import SpoiledRupee from "@/components/base/SpoiledRupee";

export default {
    name: "SpoiledLocation",
    components: {SpoiledRupee},
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        showSpoilers() {
            return this.$store.getters['Settings/showSpoilers']
                || this.$store.getters['Settings/showFound'] && this.value.done;
        },
        showTraps() {
            return this.$store.getters['Settings/showTraps']
                || this.$store.getters['Settings/showFound'] && this.value.done;
        },
        showPrices() {
            return this.$store.getters['Settings/showPrices']
                || this.$store.getters['Settings/showFound'] && this.value.done;
        },
    },
    methods: {
        setDone() {
            this.$store.commit('Log/setLocationDone', {
                location: this.value,
                done: !this.value.done,
            });
        }
    },
}
</script>

<style scoped>

</style>