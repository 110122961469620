<template>
    <v-layout d-flex column fill-height justify-center align-center>
        <v-spacer/>

        <v-layout d-flex column shrink>
            <v-layout d-flex shrink align-center class="mb-10">
                <spoiled-logo :size="135" class="mt-8"/>

                <v-layout d-flex column align-center justify-start class="nocopy">
                    <v-flex class="appTitle triforce">
                        {{ appName }}
                    </v-flex>

                    <div class="triforce">Ship of Harkinian Spoiler Viewer</div>
                </v-layout>
            </v-layout>

            <v-flex shrink v-if="hasHistory" class="mb-3 px-16">
                <spoiled-history/>
            </v-flex>
            <v-flex shrink class="px-16">
                <spoiled-upload/>
            </v-flex>
        </v-layout>

        <v-spacer/>

        <div class="subtext text--secondary mb-3 nocopy">{{ appVersion }}</div>
    </v-layout>
</template>

<script>
import SpoiledUpload from "@/components/base/SpoiledUpload";
import appInfo from "@/helper/appInfo";
import SpoiledLogo from "@/components/base/SpoiledLogo";
import SpoiledHistory from "@/components/base/SpoiledHistory";

export default {
    name: "SpoiledPortal",
    components: {SpoiledHistory, SpoiledLogo, SpoiledUpload},
    computed: {
        appName() {
            return appInfo.name;
        },
        appVersion() {
            return appInfo.version;
        },
        hasHistory() {
            return !!this.$store.getters['Log/history'].length;
        },
    },
}
</script>

<style scoped>
.appTitle {
    font-size: 9rem;
    margin-bottom: -4.5rem;
    font-weight: normal;
}
</style>