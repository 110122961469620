<template>
    <v-layout d-flex shrink v-if="!!amount">
        <div>{{ amount }}</div>
        <v-img :src="rupee" :width="size" :height="size" class="rupee" contain/>
    </v-layout>
</template>

<script>
export default {
    name: "SpoiledRupee",
    props: {
        size: {
            required: true,
        },
        amount: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        rupee() {
            return require('@/assets/rupee.webp');
        },
    },
}
</script>

<style scoped>
.rupee {
    filter: invert(51%) sepia(26%) saturate(3965%) hue-rotate(83deg) brightness(121%) contrast(123%);
}
</style>