import latest from "@/helper/parser/latest";
import v702 from "@/helper/parser/v702";
import v711 from '@/helper/parser/v711';

export default {
    parser: [
        {parser: v702, version: '7.0.2'},
        {parser: v711, version: '7.1.1'},
        {parser: latest, version: 'latest'},
    ],

    parse(content) {
        return new Promise((resolve, reject) => {
            try {
                let data = content;
                if (typeof data === 'string') {
                    data = JSON.parse(content);
                }

                if (this.getVersion(data) < '6.0.0') reject({msg: 'Unsupported version'});

                let parser = this.find(data);
                if (!parser?.isValid(data)) reject({msg: 'Invalid log file'});

                resolve(parser.parse(data));
            } catch(e) {
                reject({msg: 'Invalid log file'});
            }
        })
    },

    getVersion(data) {
        return data['_version'] ?? data['version'];
    },
    find(data) {
        let version = /\((.+)\)/.exec(this.getVersion(data))[1];

        return this.parser.find(p => p.version >= version)?.parser;
    },
}