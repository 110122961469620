<template>
    <v-app>
        <div class="background"/>

        <v-main>
            <spoiled-log v-if="loaded"/>
            <spoiled-portal v-else/>
        </v-main>
    </v-app>
</template>

<script>
import SpoiledPortal from "@/components/SpoiledPortal";
import SpoiledLog from "@/components/SpoiledLog";

export default {
    name: 'App',

    components: {
        SpoiledLog,
        SpoiledPortal,
    },

    metaInfo: {
        title: 'Spoiled - Ship of Harkinian Spoiler Viewer'
    },

    computed: {
        loaded() {
            return !!this.$store.getters['Log/id'];
        },
    },

    created() {
        this.$vuetify.theme.dark = this.$store.getters['Settings/darkMode'];

        if (this.$route.params.id) {
            this.$store.dispatch('Log/loadFromServer', this.$route.params.id);
        }
    },
};
</script>

<style>
.triforce {
    font-family: 'Triforce', sans-serif !important;
}

.background {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: url('@/assets/background.webp') !important;
    background-position: center;
    background-size: cover;
    filter: brightness(50%);
}

.subtext {
    font-size: .75rem;
}

.nocopy {
    user-select: none;
}

.no-spoilers {
    filter: blur(7.5px);
}

.interactable {
    cursor: pointer;
}
</style>