<template>
    <v-img
        :src="logo"
        :min-width="size"
        :width="size"
        :max-width="size"
        :min-height="size"
        :height="size"
        :max-height="size"
        class="logo"
        contain
    />
</template>

<script>
export default {
    name: "SpoiledLogo",
    props: {
        size: {
            required: true,
        },
    },
    computed: {
        logo() {
            return require('@/assets/logo.webp');
        },
    },
}
</script>

<style scoped>
.logo {
    filter: brightness(0) saturate(100%) invert(24%) sepia(31%) saturate(2256%) hue-rotate(240deg) brightness(150%) contrast(104%);
}
</style>