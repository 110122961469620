<template>
    <v-layout d-flex column fill-height>
        <spoiled-area-filter v-model="areas" @input="updatePage" class="mb-3"/>

        <spoiled-search v-model="search" class="mb-5"/>

        <v-flex grow class="mb-3">
            <v-data-iterator
                :items="locations"
                item-key="name"
                :items-per-page="itemsPerPage"
                :page="page"
                hide-default-footer
            >
                <template v-slot:default="{ items }">
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="3" v-for="location in items" :key="location.name">
                            <spoiled-location :value="location"/>
                        </v-col>
                    </v-row>
                </template>
            </v-data-iterator>
        </v-flex>

        <v-pagination :length="pages" :total-visible="15" v-model="page"/>
    </v-layout>
</template>

<script>
import SpoiledLocation from "@/components/base/SpoiledLocation";
import SpoiledAreaFilter from "@/components/base/SpoiledAreaFilter";
import SpoiledSearch from "@/components/base/SpoiledSearch.vue";

export default {
    name: "SpoiledLocations",
    components: {SpoiledSearch, SpoiledAreaFilter, SpoiledLocation},
    data: function () {
        return {
            search: null,
            page: 1,
            itemsPerPage: 20,
            areas: [],
        };
    },
    computed: {
        locations() {
            return this.$options.filters.byArea(
                this.$store.getters['Log/locations'],
                this.areas
            )
                .filter(l =>
                    !this.search
                    || l.id.toLowerCase().includes(this.search.toLowerCase())
                    || (
                        (this.$store.getters['Settings/showSpoilers'] || (l.done && this.$store.getters['Settings/showFound']))
                        && l.item.toLowerCase().includes(this.search.toLowerCase())
                    )
                );
        },
        pages() {
            return Math.ceil(this.locations.length / this.itemsPerPage) || 1;
        },
    },
    methods: {
        updatePage() {
            this.page = this.page > this.pages ? this.pages : this.page;
        },
    },
    watch: {
        search() {
            this.updatePage();
        },
        '$store.state.Log.log.id'() {
            this.page = 1;
        },
    },
}
</script>

<style scoped>

</style>