import { render, staticRenderFns } from "./SpoiledNavigation.vue?vue&type=template&id=49f81b40&scoped=true&"
import script from "./SpoiledNavigation.vue?vue&type=script&lang=js&"
export * from "./SpoiledNavigation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49f81b40",
  null
  
)

export default component.exports