<template>
    <v-flex shrink>
        <v-text-field
            solo
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            clearable
            v-model="search"
        />
    </v-flex>
</template>

<script>
export default {
    name: "SpoiledSearch",
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    computed: {
        search: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    watch: {
        '$store.state.Log.log.id'() {
            this.search = null;
        },
    },
}
</script>

<style scoped>

</style>