<template>
    <v-dialog v-model="open">
        <template v-slot:activator="{ on }">
            <v-btn
                text
                v-on="on"
            >
                <v-icon class="mr-1">mdi-copyright</v-icon>
                <div>Bytenex</div>
            </v-btn>
        </template>

        <v-card flat>
            <v-toolbar dense flat>
                <div>Changelog</div>

                <v-spacer/>

                <v-btn icon @click="open = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <div class="log pa-3">
                <div v-for="log in changelog" :key="log.version" class="mb-3">
                    <div>{{ log.version }}</div>
                    <ul>
                        <li v-for="(change, key) in log.changes" :key="key">{{ change }}</li>
                    </ul>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import appInfo from "@/helper/appInfo";

export default {
    name: "SpoiledChangelog",
    data: function () {
        return {
            open: false,
        };
    },
    computed: {
        changelog() {
            return appInfo.changelog.reverse();
        },
    },
}
</script>

<style scoped>
.log {
    max-height: 80vh;
    overflow: auto;
}
</style>