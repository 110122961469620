<template>
    <v-card flat tile>
        <v-layout d-flex column fill-height>
            <v-flex grow class="route pr-3">
                <v-timeline dense>
                    <v-timeline-item
                        v-for="node in route"
                        :key="node.id"
                        icon="mdi-map-marker"
                        class="pb-1"
                        fill-dot
                        small
                    >
                        <v-layout d-flex justify-space-between align-center>
                            <div>{{ node.label }}</div>

                            <v-tooltip bottom :open-delay="1000">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="select(node)" v-on="on">
                                        <v-icon>
                                            mdi-image-filter-center-focus
                                        </v-icon>
                                    </v-btn>
                                </template>

                                Focus waypoint
                            </v-tooltip>
                        </v-layout>
                    </v-timeline-item>
                </v-timeline>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
import Entrances from "@/helper/entrances";

export default {
    name: "SpoiledRoute",
    props: {
        value: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        route() {
            return this.value.map(n => Entrances.entrances.find(e => e.id === n))
                .filter(e => !e.area);
        },
    },
    methods: {
        select(node) {
            this.$emit('select', node.id);
        },
    },
}
</script>

<style scoped>
.route {
    height: 0;
    overflow-y: auto;
}
</style>