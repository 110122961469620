<template>
    <div>
        <spoiled-toggle-button title="Found" v-model="showFound" class="mx-1"/>
        <spoiled-toggle-button title="Spoilers" v-model="showSpoilers" class="mx-1"/>
        <spoiled-toggle-button title="Traps" v-model="showTraps" class="mx-1"/>
        <spoiled-toggle-button title="Prices" v-model="showPrices" class="mx-1"/>
    </div>
</template>

<script>
import SpoiledToggleButton from "@/components/settings/SpoiledToggleButton";

export default {
    name: "SpoiledShowSettings",
    components: {SpoiledToggleButton},
    computed: {
        showFound: {
            get() {
                return this.$store.getters['Settings/showFound'];
            },
            set(value) {
                this.$store.commit('Settings/setFound', value);
            },
        },
        showSpoilers: {
            get() {
                return this.$store.getters['Settings/showSpoilers'];
            },
            set(value) {
                this.$store.commit('Settings/setSpoilers', value);
            },
        },
        showTraps: {
            get() {
                return this.$store.getters['Settings/showTraps'];
            },
            set(value) {
                this.$store.commit('Settings/setTraps', value);
            },
        },
        showPrices: {
            get() {
                return this.$store.getters['Settings/showPrices'];
            },
            set(value) {
                this.$store.commit('Settings/setPrices', value);
            },
        },
    },
}
</script>

<style scoped>

</style>