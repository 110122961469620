import helper from "@/helper/helper";

export default {
    entrances: [
        {
            index: '0x0000',
            label: 'Deku Tree Entrance',
            exit: '0x0209',
        },
        {
            index: '0x0209',
            label: 'Deku Tree Entryway',
            exit: '0x0000',
        },
        {
            index: '0x0004',
            label: 'Dodongo\'s Cavern Entrance',
            exit: '0x0242',
        },
        {
            index: '0x0242',
            label: 'Dodongo\'s Cavern Entryway',
            exit: '0x0004',
        },
        {
            index: '0x0028',
            label: 'ZF Jabu Jabu Entrance',
            exit: '0x0221',
        },
        {
            index: '0x0221',
            label: 'ZF Jabu Jabu\'s Entryway',
            exit: '0x0028',
        },
        {
            index: '0x0169',
            label: 'Forest Temple Entrance',
            exit: '0x0215',
        },
        {
            index: '0x0215',
            label: 'Forest Temple Entryway',
            exit: '0x0169',
        },
        {
            index: '0x0165',
            label: 'Fire Temple Entrance',
            exit: '0x024A',
        },
        {
            index: '0x024A',
            label: 'Fire Temple Entryway',
            exit: '0x0165',
        },
        {
            index: '0x0010',
            label: 'Water Temple Entrance',
            exit: '0x021D',
        },
        {
            index: '0x021D',
            label: 'Water Temple Entryway',
            exit: '0x0010',
        },
        {
            index: '0x0082',
            label: 'Spirit Temple Entrance',
            exit: '0x01E1',
        },
        {
            index: '0x01E1',
            label: 'Spirit Temple Entryway',
            exit: '0x0082',
        },
        {
            index: '0x0037',
            label: 'Shadow Temple Entrance',
            exit: '0x0205',
        },
        {
            index: '0x0205',
            label: 'Shadow Temple Entryway',
            exit: '0x0037',
        },
        {
            index: '0x0098',
            label: 'Bottom of the Well Entrance',
            exit: '0x02A6',
        },
        {
            index: '0x02A6',
            label: 'Bottom of the Well Entryway',
            exit: '0x0098',
        },
        {
            index: '0x0088',
            label: 'ZF Ice Cavern Entrance',
            exit: '0x03D4',
        },
        {
            index: '0x03D4',
            label: 'Ice Cavern Entryway',
            exit: '0x0088',
        },
        {
            index: '0x0008',
            label: 'GF Gerudo Training Grounds Entrance',
            exit: '0x03A8',
        },
        {
            index: '0x03A8',
            label: 'Gerudo Training Grounds Entryway',
            exit: '0x0008',
        },
        {
            index: '0x0467',
            label: 'OGC Ganon\'s Castle Entrance',
            exit: '0x023D',
        },
        {
            index: '0x023D',
            label: 'Ganon\'s Castle Entryway',
            exit: '0x0467',
        },
        {
            index: '0x0433',
            label: 'Mido\'s House Entrance',
            exit: '0x0443',
        },
        {
            index: '0x0443',
            label: 'Mido\'s House Entryway',
            exit: '0x0433',
        },
        {
            index: '0x0437',
            label: 'Saria\'s House Entrance',
            exit: '0x0447',
        },
        {
            index: '0x0447',
            label: 'Saria\'s House Entryway',
            exit: '0x0437',
        },
        {
            index: '0x009C',
            label: 'Twins House Entrance',
            exit: '0x033C',
        },
        {
            index: '0x033C',
            label: 'Twins House Entryway',
            exit: '0x009C',
        },
        {
            index: '0x00C9',
            label: 'Know it all House Entrance',
            exit: '0x026A',
        },
        {
            index: '0x026A',
            label: 'Know it all House Entryway',
            exit: '0x00C9',
        },
        {
            index: '0x00C1',
            label: 'Kokiri Shop Entrance',
            exit: '0x0266',
        },
        {
            index: '0x0266',
            label: 'Kokiri Shop Entryway',
            exit: '0x00C1',
        },
        {
            index: '0x0043',
            label: 'Lab Entrance',
            exit: '0x03CC',
        },
        {
            index: '0x03CC',
            label: 'Lab Entryway',
            exit: '0x0043',
        },
        {
            index: '0x045F',
            label: 'Fishing Hole Entrance',
            exit: '0x0309',
        },
        {
            index: '0x0309',
            label: 'Fishing Hole Entryway',
            exit: '0x045F',
        },
        {
            index: '0x03A0',
            label: 'GV Carpenter Tent Entrance',
            exit: '0x03D0',
        },
        {
            index: '0x03D0',
            label: 'GV Carpenter Tent Entryway',
            exit: '0x03A0',
        },
        {
            index: '0x007E',
            label: 'ME Guard House Entrance',
            exit: '0x026E',
        },
        {
            index: '0x026E',
            label: 'ME Guard House Entryway',
            exit: '0x007E',
        },
        {
            index: '0x0530',
            label: 'Mask Shop Entrance',
            exit: '0x01D1',
        },
        {
            index: '0x01D1',
            label: 'Mask Shop Entryway',
            exit: '0x0530',
        },
        {
            index: '0x0507',
            label: 'Bombchu Bowling Entrance',
            exit: '0x03BC',
        },
        {
            index: '0x03BC',
            label: 'Bombchu Bowling Entryway',
            exit: '0x0507',
        },
        {
            index: '0x0388',
            label: 'Potion Shop Entrance',
            exit: '0x02A2',
        },
        {
            index: '0x02A2',
            label: 'Potion Shop Entryway',
            exit: '0x0388',
        },
        {
            index: '0x0063',
            label: 'Treasure Chest Game Entrance',
            exit: '0x01D5',
        },
        {
            index: '0x01D5',
            label: 'Treasure Chest Game Entryway',
            exit: '0x0063',
        },
        {
            index: '0x0528',
            label: 'Bombchu Shop Entrance',
            exit: '0x03C0',
        },
        {
            index: '0x03C0',
            label: 'Bombchu Shop Entryway',
            exit: '0x0528',
        },
        {
            index: '0x043B',
            label: 'Man in green House Entrance',
            exit: '0x0067',
        },
        {
            index: '0x0067',
            label: 'Man in green House Entryway',
            exit: '0x043B',
        },
        {
            index: '0x02FD',
            label: 'Kakariko Carpenter Boss House Entrance',
            exit: '0x0349',
        },
        {
            index: '0x0349',
            label: 'Kakariko Carpenter Boss House Entryway',
            exit: '0x02FD',
        },
        {
            index: '0x0550',
            label: 'Kakariko House of Skulltulla Entrance',
            exit: '0x04EE',
        },
        {
            index: '0x04EE',
            label: 'Kakariko House of Skulltulla Entryway',
            exit: '0x0550',
        },
        {
            index: '0x039C',
            label: 'Impa\'s House Entrance',
            exit: '0x0345',
        },
        {
            index: '0x0345',
            label: 'Impa\'s House Entryway',
            exit: '0x039C',
        },
        {
            index: '0x05C8',
            label: 'Impa\'s House Back Entrance',
            exit: '0x05DC',
        },
        {
            index: '0x05DC',
            label: 'Impa\'s House Back Entryway',
            exit: '0x05C8',
        },
        {
            index: '0x0072',
            label: 'Kakariko Odd Potion Building Entrance',
            exit: '0x034D',
        },
        {
            index: '0x034D',
            label: 'Kakariko Odd Potion Building Entryway',
            exit: '0x0072',
        },
        {
            index: '0x030D',
            label: 'GY Dampe\'s House Entrance',
            exit: '0x0355',
        },
        {
            index: '0x0355',
            label: 'GY Dampe\'s House Entryway',
            exit: '0x030D',
        },
        {
            index: '0x037C',
            label: 'GC Shop Entrance',
            exit: '0x03FC',
        },
        {
            index: '0x03FC',
            label: 'GC Shop Entryway',
            exit: '0x037C',
        },
        {
            index: '0x0380',
            label: 'ZD Shop Entrance',
            exit: '0x03C4',
        },
        {
            index: '0x03C4',
            label: 'ZD Shop Entryway',
            exit: '0x0380',
        },
        {
            index: '0x004F',
            label: 'Talon\'s House Entrance',
            exit: '0x0378',
        },
        {
            index: '0x0378',
            label: 'Talon\'s House Entryway',
            exit: '0x004F',
        },
        {
            index: '0x02F9',
            label: 'Stables Entrance',
            exit: '0x042F',
        },
        {
            index: '0x042F',
            label: 'Stables Entryway',
            exit: '0x02F9',
        },
        {
            index: '0x05D0',
            label: 'Lon Lon Ranch Tower Entrance',
            exit: '0x05D4',
        },
        {
            index: '0x05D4',
            label: 'Lon Lon Ranch Tower Entryway',
            exit: '0x05D0',
        },
        {
            index: '0x052C',
            label: 'Market Bazaar Entrance',
            exit: '0x03B8',
        },
        {
            index: '0x03B8',
            label: 'Market Bazaar Entryway',
            exit: '0x052C',
        },
        {
            index: '0x016D',
            label: 'Market Shooting Gallery Entrance',
            exit: '0x01CD',
        },
        {
            index: '0x01CD',
            label: 'Market Shooting Gallery Entryway',
            exit: '0x016D',
        },
        {
            index: '0x00B7',
            label: 'Kakariko Bazaar Entrance',
            exit: '0x0201',
        },
        {
            index: '0x0201',
            label: 'Kakariko Bazaar Entryway',
            exit: '0x00B7',
        },
        {
            index: '0x003B',
            label: 'Kakariko Shooting Gallery Entrance',
            exit: '0x0463',
        },
        {
            index: '0x0463',
            label: 'Kakariko Shooting Gallery Entryway',
            exit: '0x003B',
        },
        {
            index: '0x0588',
            label: 'Desert Colossus Great Fairy Fountain Entrance',
            exit: '0x057C',
        },
        {
            index: '0x057C',
            label: 'Desert Colossus Great Fairy Fountain Entryway',
            exit: '0x0588',
        },
        {
            index: '0x0578',
            label: 'HC Great Fairy Fountain Entrance',
            exit: '0x0340',
        },
        {
            index: '0x0340',
            label: 'HC Great Fairy Fountain Entryway',
            exit: '0x0578',
        },
        {
            index: '0x04C2',
            label: 'OGC Great Fairy Fountain Entrance',
            exit: '0x03E8',
        },
        {
            index: '0x03E8',
            label: 'OGC Great Fairy Fountain Entryway',
            exit: '0x04C2',
        },
        {
            index: '0x04BE',
            label: 'DMC Great Fairy Fountain Entrance',
            exit: '0x0482',
        },
        {
            index: '0x0482',
            label: 'DMC Great Fairy Fountain Entryway',
            exit: '0x04BE',
        },
        {
            index: '0x0315',
            label: 'DMT Great Fairy Fountain Entrance',
            exit: '0x045B',
        },
        {
            index: '0x045B',
            label: 'DMT Great Fairy Fountain Entryway',
            exit: '0x0315',
        },
        {
            index: '0x0371',
            label: 'ZF Great Fairy Fountain Entrance',
            exit: '0x0394',
        },
        {
            index: '0x0394',
            label: 'ZF Great Fairy Fountain Entryway',
            exit: '0x0371',
        },
        {
            index: '0x0272',
            label: 'Link\'s House Entrance',
            exit: '0x0211',
        },
        {
            index: '0x0211',
            label: 'Link\'s House Entryway',
            exit: '0x0272',
        },
        {
            index: '0x0053',
            label: 'Temple of Time Entrance',
            exit: '0x0472',
        },
        {
            index: '0x0472',
            label: 'Temple of Time Entryway',
            exit: '0x0053',
        },
        {
            index: '0x0453',
            label: 'Windmill Entrance',
            exit: '0x0351',
        },
        {
            index: '0x0351',
            label: 'Windmill Entryway',
            exit: '0x0453',
        },
        {
            index: '0x0384',
            label: 'Kakariko Potion Shop Entrance',
            exit: '0x044B',
        },
        {
            index: '0x044B',
            label: 'Kakariko Potion Shop Entryway',
            exit: '0x0384',
        },
        {
            index: '0x03EC',
            label: 'Kakariko Potion Shop Back Entrance',
            exit: '0x04FF',
        },
        {
            index: '0x04FF',
            label: 'Kakariko Potion Shop Back Entryway',
            exit: '0x03EC',
        },
        {
            index: '0x05E0',
            label: 'Lost Woods Bridge Entrance',
            exit: '0x020D',
        },
        {
            index: '0x020D',
            label: 'Bridge from Kokiri Forest',
            exit: '0x05E0',
        },
        {
            index: '0x011E',
            label: 'KF Maze Entrance',
            exit: '0x0286',
        },
        {
            index: '0x0286',
            label: 'LW Maze Entryway',
            exit: '0x011E',
        },
        {
            index: '0x04E2',
            label: 'LW Goron City Entrance',
            exit: '0x04D6',
        },
        {
            index: '0x04D6',
            label: 'GC Lost Woods Entrance',
            exit: '0x04E2',
        },
        {
            index: '0x01DD',
            label: 'LW Zora\'s River Entrance',
            exit: '0x04DA',
        },
        {
            index: '0x04DA',
            label: 'ZR Lost Woods Entrance',
            exit: '0x01DD',
        },
        {
            index: '0x00FC',
            label: 'Sacred Forest Meadow Entrance',
            exit: '0x01A9',
        },
        {
            index: '0x01A9',
            label: 'Sacred Forest Meadow Entryway',
            exit: '0x00FC',
        },
        {
            index: '0x0185',
            label: 'LW Hyrule Field Entrance',
            exit: '0x04DE',
        },
        {
            index: '0x04DE',
            label: 'HF Lost Woods Entrance',
            exit: '0x0185',
        },
        {
            index: '0x0102',
            label: 'HF Lake Hylia Entrance',
            exit: '0x0189',
        },
        {
            index: '0x0189',
            label: 'LH Hyrule Field Entrance',
            exit: '0x0102',
        },
        {
            index: '0x0117',
            label: 'HF Gerudo Valley Entrance',
            exit: '0x018D',
        },
        {
            index: '0x018D',
            label: 'GV Hyrule Field Entrance',
            exit: '0x0117',
        },
        {
            index: '0x0276',
            label: 'HF Market Entrance',
            exit: '0x01FD',
        },
        {
            index: '0x01FD',
            label: 'ME Hyrule Field Entrance',
            exit: '0x0276',
        },
        {
            index: '0x00DB',
            label: 'HF Kakariko Entrance',
            exit: '0x017D',
        },
        {
            index: '0x017D',
            label: 'Kakariko Hyrule Field Entrance',
            exit: '0x00DB',
        },
        {
            index: '0x00EA',
            label: 'HF Zora\'s River Entrance',
            exit: '0x0181',
        },
        {
            index: '0x0181',
            label: 'ZR Hyrule Field Entrance',
            exit: '0x00EA',
        },
        {
            index: '0x0157',
            label: 'Lon Lon Ranch Entrance',
            exit: '0x01F9',
        },
        {
            index: '0x01F9',
            label: 'Lon Lon Ranch Entryway',
            exit: '0x0157',
        },
        {
            index: '0x0328',
            label: 'LH Underwater Passage Entrance',
            exit: '0x0560',
        },
        {
            index: '0x0560',
            label: 'ZD Underwater Passage Entrance',
            exit: '0x0328',
        },
        {
            index: '0x0129',
            label: 'GV Gerudo Fortress Entrance',
            exit: '0x022D',
        },
        {
            index: '0x022D',
            label: 'Gerudo Fortress Entryway',
            exit: '0x0129',
        },
        {
            index: '0x0130',
            label: 'GF Wasteland Entrance',
            exit: '0x03AC',
        },
        {
            index: '0x03AC',
            label: 'Wasteland Gerudo Fortress Entrance',
            exit: '0x0130',
        },
        {
            index: '0x0123',
            label: 'Desert Colossus Entrance',
            exit: '0x0365',
        },
        {
            index: '0x0365',
            label: 'Colossus Wasteland Entrance',
            exit: '0x0123',
        },
        {
            index: '0x00B1',
            label: 'ME Market Entrance',
            exit: '0x0033',
        },
        {
            index: '0x0033',
            label: 'Market Entryway',
            exit: '0x00B1',
        },
        {
            index: '0x0138',
            label: 'Hyrule Castle Entrance',
            exit: '0x025A',
        },
        {
            index: '0x025A',
            label: 'Hyrule Castle Entryway',
            exit: '0x0138',
        },
        {
            index: '0x0171',
            label: 'Temple of Time Garden Entrance',
            exit: '0x025E',
        },
        {
            index: '0x025E',
            label: 'ToT Market Entrance',
            exit: '0x0171',
        },
        {
            index: '0x00E4',
            label: 'Graveyard Entrance',
            exit: '0x0195',
        },
        {
            index: '0x0195',
            label: 'GY Kakariko Entrance',
            exit: '0x00E4',
        },
        {
            index: '0x013D',
            label: 'Kakariko Death Mountain Trail Entrance',
            exit: '0x0191',
        },
        {
            index: '0x0191',
            label: 'DMT Kakariko Entrance',
            exit: '0x013D',
        },
        {
            index: '0x01B9',
            label: 'GC Death Mountain Trail Entrance',
            exit: '0x014D',
        },
        {
            index: '0x014D',
            label: 'DMT Goron City Entrance',
            exit: '0x01B9',
        },
        {
            index: '0x0246',
            label: 'GC Death Mountain Crater Entrance',
            exit: '0x01C1',
        },
        {
            index: '0x01C1',
            label: 'DMC Goron City Entrance',
            exit: '0x0246',
        },
        {
            index: '0x0147',
            label: 'DMT Death Mountain Crater Entrance',
            exit: '0x01BD',
        },
        {
            index: '0x01BD',
            label: 'DMC Death Mountain Trail Entrance',
            exit: '0x0147',
        },
        {
            index: '0x0108',
            label: 'ZR Zora\'s Domain Entrance',
            exit: '0x019D',
        },
        {
            index: '0x019D',
            label: 'ZD Zora\'s River Entrance',
            exit: '0x0108',
        },
        {
            index: '0x0225',
            label: 'ZD Zora\'s Fountain Entrance',
            exit: '0x01A1',
        },
        {
            index: '0x01A1',
            label: 'ZF Zora\'s Domain Entrance',
            exit: '0x0225',
        },
        {
            index: '0x040F',
            label: 'DT Boss Entrance',
            exit: '0x0252',
        },
        {
            index: '0x0252',
            label: 'DT Boss Room',
            exit: '0x040F',
        },
        {
            index: '0x040B',
            label: 'DC Boss Entrance',
            exit: '0x00C5',
        },
        {
            index: '0x00C5',
            label: 'DC Boss Room',
            exit: '0x040B',
        },
        {
            index: '0x0301',
            label: 'JJB Boss Entrance',
            exit: '0x0407',
        },
        {
            index: '0x0407',
            label: 'JJB Boss Room',
            exit: '0x0301',
        },
        {
            index: '0x000C',
            label: 'FT Boss Entrance',
            exit: '0x024E',
        },
        {
            index: '0x024E',
            label: 'FT Boss Room',
            exit: '0x000C',
        },
        {
            index: '0x0305',
            label: 'FiT Boss Entrance',
            exit: '0x0175',
        },
        {
            index: '0x0175',
            label: 'FiT Boss Room',
            exit: '0x0305',
        },
        {
            index: '0x0417',
            label: 'WT Boss Entrance',
            exit: '0x0423',
        },
        {
            index: '0x0423',
            label: 'WT Boss Room',
            exit: '0x0417',
        },
        {
            index: '0x008D',
            label: 'SpT Boss Entrance',
            exit: '0x02F5',
        },
        {
            index: '0x02F5',
            label: 'SpT Boss Room',
            exit: '0x008D',
        },
        {
            index: '0x0413',
            label: 'ST Boss Entrance',
            exit: '0x02B2',
        },
        {
            index: '0x02B2',
            label: 'ST Boss Room',
            exit: '0x0413',
        },
        {
            index: '0x0700',
            label: 'Colossus Grotto Entrance',
            exit: '0x0800',
        },
        {
            index: '0x0800',
            label: 'Colossus Grotto Entryway',
            exit: '0x0700',
        },
        {
            index: '0x0701',
            label: 'LH Grotto Entrance',
            exit: '0x0801',
        },
        {
            index: '0x0801',
            label: 'LH Grotto Entryway',
            exit: '0x0701',
        },
        {
            index: '0x0702',
            label: 'ZR Storms Grotto Entrance',
            exit: '0x0802',
        },
        {
            index: '0x0802',
            label: 'ZR Storms Grotto Entryway',
            exit: '0x0702',
        },
        {
            index: '0x0703',
            label: 'ZR Fairy Grotto Entrance',
            exit: '0x0803',
        },
        {
            index: '0x0803',
            label: 'ZR Fairy Grotto Entryway',
            exit: '0x0703',
        },
        {
            index: '0x0704',
            label: 'ZR Open Grotto Entrance',
            exit: '0x0804',
        },
        {
            index: '0x0804',
            label: 'ZR Open Grotto Entryway',
            exit: '0x0704',
        },
        {
            index: '0x0705',
            label: 'DMC Hammer Grotto Entrance',
            exit: '0x0805',
        },
        {
            index: '0x0805',
            label: 'DMC Hammer Grotto Entryway',
            exit: '0x0705',
        },
        {
            index: '0x0706',
            label: 'DMC Upper Grotto Entrance',
            exit: '0x0806',
        },
        {
            index: '0x0806',
            label: 'DMC Upper Grotto Entryway',
            exit: '0x0706',
        },
        {
            index: '0x0707',
            label: 'GC Grotto Entrance',
            exit: '0x0807',
        },
        {
            index: '0x0807',
            label: 'GC Grotto Entryway',
            exit: '0x0707',
        },
        {
            index: '0x0708',
            label: 'DMT Storms Grotto Entrance',
            exit: '0x0808',
        },
        {
            index: '0x0808',
            label: 'DMT Storms Grotto Entryway',
            exit: '0x0708',
        },
        {
            index: '0x0709',
            label: 'DMT Cow Grotto Entrance',
            exit: '0x0809',
        },
        {
            index: '0x0809',
            label: 'DMT Cow Grotto Entryway',
            exit: '0x0709',
        },
        {
            index: '0x070A',
            label: 'Kakariko Open Grotto Entrance',
            exit: '0x080A',
        },
        {
            index: '0x080A',
            label: 'Kakariko Open Grotto Entryway',
            exit: '0x070A',
        },
        {
            index: '0x070B',
            label: 'Kakariko Redead Grotto Entrance',
            exit: '0x080B',
        },
        {
            index: '0x080B',
            label: 'Kakariko Redead Grotto Entryway',
            exit: '0x070B',
        },
        {
            index: '0x070C',
            label: 'HC Storms Grotto Entrance',
            exit: '0x080C',
        },
        {
            index: '0x080C',
            label: 'HC Storms Grotto Entryway',
            exit: '0x070C',
        },
        {
            index: '0x070D',
            label: 'HF Tektite Grotto Entrance',
            exit: '0x080D',
        },
        {
            index: '0x080D',
            label: 'HF Tektite Grotto Entryway',
            exit: '0x070D',
        },
        {
            index: '0x070E',
            label: 'HF Near Kakariko Grotto Entrance',
            exit: '0x080E',
        },
        {
            index: '0x080E',
            label: 'HF Near Kakariko Grotto Entryway',
            exit: '0x070E',
        },
        {
            index: '0x070F',
            label: 'HF Fairy Grotto Entrance',
            exit: '0x080F',
        },
        {
            index: '0x080F',
            label: 'HF Fairy Grotto Entryway',
            exit: '0x070F',
        },
        {
            index: '0x0710',
            label: 'HF Near Market Grotto Entrance',
            exit: '0x0810',
        },
        {
            index: '0x0810',
            label: 'HF Near Market Grotto Entryway',
            exit: '0x0710',
        },
        {
            index: '0x0711',
            label: 'HF Cow Grotto Entrance',
            exit: '0x0811',
        },
        {
            index: '0x0811',
            label: 'HF Cow Grotto Entryway',
            exit: '0x0711',
        },
        {
            index: '0x0712',
            label: 'HF Inside Fence Grotto Entrance',
            exit: '0x0812',
        },
        {
            index: '0x0812',
            label: 'HF Inside Fence Grotto Entryway',
            exit: '0x0712',
        },
        {
            index: '0x0713',
            label: 'HF Open Grotto Entrance',
            exit: '0x0813',
        },
        {
            index: '0x0813',
            label: 'HF Open Grotto Entryway',
            exit: '0x0713',
        },
        {
            index: '0x0714',
            label: 'HF Southeast Grotto Entrance',
            exit: '0x0814',
        },
        {
            index: '0x0814',
            label: 'HF Southeast Grotto Entryway',
            exit: '0x0714',
        },
        {
            index: '0x0715',
            label: 'LLR Grotto Entrance',
            exit: '0x0815',
        },
        {
            index: '0x0815',
            label: 'LLR Grotto Entryway',
            exit: '0x0715',
        },
        {
            index: '0x0716',
            label: 'SFM Grotto Entrance',
            exit: '0x0816',
        },
        {
            index: '0x0816',
            label: 'SFM Grotto Entryway',
            exit: '0x0716',
        },
        {
            index: '0x0717',
            label: 'SFM Storms Grotto Entrance',
            exit: '0x0817',
        },
        {
            index: '0x0817',
            label: 'SFM Storms Grotto Entryway',
            exit: '0x0717',
        },
        {
            index: '0x0718',
            label: 'SFM Fairy Grotto Entrance',
            exit: '0x0818',
        },
        {
            index: '0x0818',
            label: 'SFM Fairy Grotto Entryway',
            exit: '0x0718',
        },
        {
            index: '0x0719',
            label: 'LW Scrubs Grotto Entrance',
            exit: '0x0819',
        },
        {
            index: '0x0819',
            label: 'LW Scrubs Grotto Entryway',
            exit: '0x0719',
        },
        {
            index: '0x071A',
            label: 'LW Near Shortcuts Grotto Entrance',
            exit: '0x081A',
        },
        {
            index: '0x081A',
            label: 'LW Near Shortcuts Grotto Entryway',
            exit: '0x071A',
        },
        {
            index: '0x071B',
            label: 'KF Storms Grotto Entrance',
            exit: '0x081B',
        },
        {
            index: '0x081B',
            label: 'KF Storms Grotto Entryway',
            exit: '0x071B',
        },
        {
            index: '0x071C',
            label: 'ZD Storms Grotto Entrance',
            exit: '0x081C',
        },
        {
            index: '0x081C',
            label: 'ZD Storms Grotto Entryway',
            exit: '0x071C',
        },
        {
            index: '0x071D',
            label: 'GF Storms Grotto Entrance',
            exit: '0x081D',
        },
        {
            index: '0x081D',
            label: 'GF Storms Grotto Entryway',
            exit: '0x071D',
        },
        {
            index: '0x071E',
            label: 'GV Storms Grotto Entrance',
            exit: '0x081E',
        },
        {
            index: '0x081E',
            label: 'GV Storms Grotto Entryway',
            exit: '0x071E',
        },
        {
            index: '0x071F',
            label: 'GV Octorok Grotto Entrance',
            exit: '0x081F',
        },
        {
            index: '0x081F',
            label: 'GV Octorok Grotto Entryway',
            exit: '0x071F',
        },
        {
            index: '0x0720',
            label: 'LW Deku Theater Entrance',
            exit: '0x0820',
        },
        {
            index: '0x0820',
            label: 'LW Deku Theater Entryway',
            exit: '0x0720',
        },
        {
            index: '0x004B',
            label: 'GY Shield Grave Entrance',
            exit: '0x035D',
        },
        {
            index: '0x035D',
            label: 'GY Shield Grave Entryway',
            exit: '0x004B',
        },
        {
            index: '0x031C',
            label: 'GY Heart Piece Grave Entrance',
            exit: '0x0361',
        },
        {
            index: '0x0361',
            label: 'GY Heart Piece Grave Entryway',
            exit: '0x031C',
        },
        {
            index: '0x002D',
            label: 'GY Composers Grave Entrance',
            exit: '0x050B',
        },
        {
            index: '0x050B',
            label: 'GY Composers Grave Entryway',
            exit: '0x002D',
        },
        {
            index: '0x044F',
            label: 'GY Dampe\'s Grave Entrance',
            exit: '0x0359',
        },
        {
            index: '0x0359',
            label: 'GY Dampe\'s Grave Entryway',
            exit: '0x044F',
        },

        {
            index: '0x0219',
            label: 'GV River Entrance',
            exit: '0xF219',
        },
        {
            index: '0xF219',
            label: 'LH River Entryway',
        },
        {
            index: '0x027E',
            label: 'LH Owl',
            exit: '0xF27E',
        },
        {
            index: '0xF27E',
            label: 'HF Owl Entryway',
        },
        {
            index: '0x0554',
            label: 'DMT Owl',
            exit: '0xF554',
        },
        {
            index: '0xF554',
            label: 'Kakariko Owl Entryway',
        },
        {
            index: '0x00BB',
            label: 'Main Menu Child',
            exit: '0xF0BB',
            size: 8,
            color: 'saddlebrown',
            x: 50,
            y: -50,
        },
        {
            index: '0xF0BB',
            label: 'Child Spawn',
        },
        {
            index: '0x0282',
            label: 'Main Menu Adult',
            exit: '0xF282',
            size: 8,
            color: 'saddlebrown',
        },
        {
            index: '0xF282',
            label: 'Adult Spawn',
        },
        {
            index: '0x0600',
            label: 'Minuet of Forest',
            exit: '0xF600',
            size: 8,
            color: 'darkgreen',
            image: require('@/assets/map/note.webp'),
        },
        {
            index: '0xF600',
            label: 'Minuet of Forest Warp Pad',
        },
        {
            index: '0x04F6',
            label: 'Bolero of Fire',
            exit: '0xF4F6',
            size: 8,
            color: 'darkred',
            image: require('@/assets/map/note.webp'),
        },
        {
            index: '0xF4F6',
            label: 'Bolero of Fire Warp Pad',
        },
        {
            index: '0x0604',
            label: 'Serenade of Water',
            exit: '0xF604',
            size: 8,
            color: 'darkcyan',
            image: require('@/assets/map/note.webp'),
        },
        {
            index: '0xF604',
            label: 'Serenade of Water Warp Pad',
        },
        {
            index: '0x01F1',
            label: 'Requiem of Spirit',
            exit: '0xF1F1',
            size: 8,
            color: 'orangered',
            image: require('@/assets/map/note.webp'),
        },
        {
            index: '0xF1F1',
            label: 'Requiem of Spirit Warp Pad',
        },
        {
            index: '0x0568',
            label: 'Nocturne of Shadow',
            exit: '0xF568',
            size: 8,
            color: 'indigo',
            image: require('@/assets/map/note.webp'),
        },
        {
            index: '0xF568',
            label: 'Nocturne of Shadow Warp Pad',
        },
        {
            index: '0x05F4',
            label: 'Prelude of Light',
            exit: '0xF5F4',
            size: 8,
            color: 'darkgoldenrod',
            image: require('@/assets/map/note.webp'),
        },
        {
            index: '0xF5F4',
            label: 'Prelude of Light Warp Pad',
        },

        {
            label: 'Kokiri Forest',
            size: 8,
            color: 'forestgreen',
            image: require('@/assets/map/kokiri-emerald.webp'),
            area: true,
            x: 0,
            y: -100,
        },
        {
            label: 'Deku Tree',
            size: 8,
            color: 'forestgreen',
            image: require('@/assets/map/kokiri-emerald.webp'),
            area: true,
            x: 50,
            y: -100
        },
        {
            label: 'Lost Woods',
            size: 8,
            color: 'forestgreen',
            image: require('@/assets/map/kokiri-emerald.webp'),
            area: true,
            x: -50,
            y: -100
        },
        {
            label: 'Sacred Forest Meadow',
            size: 8,
            color: 'forestgreen',
            image: require('@/assets/map/kokiri-emerald.webp'),
            area: true,
            x: -100,
            y: -150
        },
        {
            label: 'Forest Temple',
            size: 8,
            color: 'darkgreen',
            image: require('@/assets/map/forest-medallion.webp'),
            area: true,
            x: -150,
            y: -200,
        },
        {
            label: 'Hyrule Field',
            size: 8,
            color: 'royalblue',
            image: require('@/assets/map/hylia.webp'),
            area: true,
            x: 0,
            y: 0,
        },
        {
            label: 'Zora\'s River',
            size: 8,
            color: 'cornflowerblue',
            image: require('@/assets/map/zora-sapphire.webp'),
            area: true,
            x: -50,
            y: -50,
        },
        {
            label: 'Zora\'s Domain',
            size: 8,
            color: 'cornflowerblue',
            image: require('@/assets/map/zora-sapphire.webp'),
            area: true,
            x: -100,
            y: -50,
        },
        {
            label: 'Zora\'s Fountain',
            size: 8,
            color: 'cornflowerblue',
            image: require('@/assets/map/zora-sapphire.webp'),
            area: true,
            x: -150,
            y: -50,
        },
        {
            label: 'Ice Cavern',
            size: 8,
            color: 'cornflowerblue',
            image: require('@/assets/map/zora-sapphire.webp'),
            area: true,
            x: -200,
            y: -100,
        },
        {
            label: 'Jabu Jabu\'s Belly',
            size: 8,
            color: 'cornflowerblue',
            image: require('@/assets/map/zora-sapphire.webp'),
            area: true,
            x: -200,
            y: -50,
        },
        {
            label: 'Kakariko',
            size: 8,
            color: 'purple',
            image: require('@/assets/map/shiekah.webp'),
            area: true,
            x: 100,
            y: -100,
        },
        {
            label: 'Bottom of the Well',
            size: 8,
            color: 'purple',
            image: require('@/assets/map/shiekah.webp'),
            area: true,
            x: 150,
            y: -50,
        },
        {
            label: 'Graveyard',
            size: 8,
            color: 'purple',
            image: require('@/assets/map/shiekah.webp'),
            area: true,
            x: 200,
            y: -100,
        },
        {
            label: 'Shadow Temple',
            size: 8,
            color: 'darkslateblue',
            image: require('@/assets/map/shadow-medallion.webp'),
            area: true,
            x: 300,
            y: -100,
        },
        {
            label: 'Death Mountain Trail',
            size: 8,
            color: 'maroon',
            image: require('@/assets/map/goron-ruby.webp'),
            area: true,
            x: 100,
            y: -200,
        },
        {
            label: 'Dodongo\'s Cavern',
            size: 8,
            color: 'maroon',
            image: require('@/assets/map/goron-ruby.webp'),
            area: true,
            x: 150,
            y: -200,
        },
        {
            label: 'Goron City',
            size: 8,
            color: 'maroon',
            image: require('@/assets/map/goron-ruby.webp'),
            area: true,
            x: 0,
            y: -200,
        },
        {
            label: 'Death Mountain Crater',
            size: 8,
            color: 'maroon',
            image: require('@/assets/map/goron-ruby.webp'),
            area: true,
            x: 50,
            y: -300,
        },
        {
            label: 'Fire Temple',
            size: 8,
            color: 'darkred',
            image: require('@/assets/map/fire-medallion.webp'),
            area: true,
            x: 50,
            y: -350,
        },
        {
            label: 'Market Entrance',
            size: 8,
            color: 'royalblue',
            image: require('@/assets/map/hylia.webp'),
            area: true,
            x: 100,
            y: 100,
        },
        {
            label: 'Market',
            size: 8,
            color: 'royalblue',
            image: require('@/assets/map/hylia.webp'),
            area: true,
            x: 200,
            y: 200,
        },
        {
            label: 'Hyrule Castle',
            size: 8,
            color: 'royalblue',
            image: require('@/assets/map/hylia.webp'),
            area: true,
            x: 200,
            y: 250,
        },
        {
            label: 'Outside Ganon\'s Castle',
            size: 8,
            color: 'darkslategrey',
            image: require('@/assets/map/ganon.webp'),
            area: true,
            x: 150,
            y: 250,
        },
        {
            label: 'Ganon\'s Castle',
            size: 8,
            color: 'darkslategrey',
            image: require('@/assets/map/ganon.webp'),
            area: true,
            x: 150,
            y: 300,
        },
        {
            label: 'Lake Hylia',
            size: 8,
            color: 'cornflowerblue',
            image: require('@/assets/map/zora-sapphire.webp'),
            area: true,
            x: -50,
            y: 0,
        },
        {
            label: 'Water Temple',
            size: 8,
            color: 'midnightblue',
            image: require('@/assets/map/water-medallion.webp'),
            area: true,
            x: -150,
            y: 0,
        },
        {
            label: 'Lon Lon Ranch',
            size: 8,
            color: 'lightskyblue',
            image: require('@/assets/map/lon-lon-ranch.webp'),
            area: true,
            x: 100,
            y: 50,
        },
        {
            label: 'Gerudo Valley',
            size: 8,
            color: 'darkorange',
            image: require('@/assets/map/gerudo.webp'),
            area: true,
            x: -100,
            y: 100,
        },
        {
            label: 'Gerudo Fortress',
            size: 8,
            color: 'darkorange',
            image: require('@/assets/map/gerudo.webp'),
            area: true,
            x: -200,
            y: 200,
        },
        {
            label: 'Gerudo Training Grounds',
            size: 8,
            color: 'darkorange',
            image: require('@/assets/map/gerudo.webp'),
            area: true,
            x: -150,
            y: 250,
        },
        {
            label: 'Haunted Wasteland',
            size: 8,
            color: 'darkorange',
            image: require('@/assets/map/gerudo.webp'),
            area: true,
            x: -250,
            y: 250,
        },
        {
            label: 'Desert Colossus',
            size: 8,
            color: 'darkorange',
            image: require('@/assets/map/gerudo.webp'),
            area: true,
            x: -300,
            y: 300,
        },
        {
            label: 'Spirit Temple',
            size: 8,
            color: 'chocolate',
            image: require('@/assets/map/spirit-medallion.webp'),
            area: true,
            x: -350,
            y: 350,
        },
        {
            label: 'Temple of Time',
            size: 8,
            color: 'goldenrod',
            image: require('@/assets/map/light-medallion.webp'),
            area: true,
            x: 250,
            y: 200,
        },
    ]
        .map(e => {
            return {
                ...e,
                id: e.label
                    .toLowerCase()
                    .replaceAll('\'', '')
                    .replaceAll(' ', '-'),
                index: parseInt(e.index, 16),
                exit: parseInt(e.exit, 16),
            };
        }),
    connections: [
        // Spawns
        {from: 'child-spawn', to: 'links-house-entryway'},
        {from: 'adult-spawn', to: 'temple-of-time'},

        // Lost Woods Bridge
        {from: 'bridge-from-kokiri-forest', to: 'lw-hyrule-field-entrance', bidirectional: true},

        // Kakariko
        {from: 'kakariko-potion-shop-entryway', to: 'kakariko-potion-shop-back-entryway', bidirectional: true},

        // Graveyard
        {from: 'nocturne-of-shadow-warp-pad', to: 'graveyard'},
        {from: 'shadow-temple-entrance', to: 'nocturne-of-shadow-warp-pad', bidirectional: true},

        // Death Mountain Trail
        {from: 'tot-market-entrance', to: 'temple-of-time-entrance', bidirectional: true},

        // Wasteland
        {from: 'wasteland-gerudo-fortress-entrance', to: 'desert-colossus-entrance', bidirectional: true},

        // Owls
        {from: 'lake-hylia', to: 'lh-owl'},
        {from: 'hf-owl-entryway', to: 'hyrule-field'},
        {from: 'death-mountain-trail', to: 'dmt-owl'},
        {from: 'kakariko-owl-entryway', to: 'kakariko'},
    ]
        // Kokiri Forest
        .concat(helper.starConnect([
            'links-house-entrance',
            'midos-house-entrance',
            'sarias-house-entrance',
            'know-it-all-house-entrance',
            'twins-house-entrance',
            'kokiri-shop-entrance',
            'deku-tree-entrance',
            'lost-woods-bridge-entrance',
            'kf-maze-entrance',
            'kf-storms-grotto-entrance',
        ], 'kokiri-forest'))
        // Deku Tree
        .concat(helper.starConnect([
            'deku-tree-entryway',
            'dt-boss-entrance',
        ], 'deku-tree'))
        // Lost Woods
        .concat(helper.starConnect([
            'lw-maze-entryway',
            'lw-goron-city-entrance',
            'lw-zoras-river-entrance',
            'lw-scrubs-grotto-entrance',
            'lw-near-shortcuts-grotto-entrance',
            'lw-deku-theater-entrance',
            'sacred-forest-meadow-entrance',
        ], 'lost-woods'))
        // Sacred Forest Meadow
        .concat(helper.starConnect([
            'sfm-grotto-entrance',
            'sfm-storms-grotto-entrance',
            'sfm-fairy-grotto-entrance',
            'sacred-forest-meadow-entryway',
            'forest-temple-entrance',
            'minuet-of-forest-warp-pad',
        ], 'sacred-forest-meadow'))
        // Forest Temple
        .concat(helper.starConnect([
            'forest-temple-entryway',
            'ft-boss-entrance',
        ], 'forest-temple'))
        // Hyrule Field
        .concat(helper.starConnect([
            'hf-lost-woods-entrance',
            'hf-lake-hylia-entrance',
            'hf-gerudo-valley-entrance',
            'hf-market-entrance',
            'hf-zoras-river-entrance',
            'hf-kakariko-entrance',
            'hf-tektite-grotto-entrance',
            'hf-near-kakariko-grotto-entrance',
            'hf-fairy-grotto-entrance',
            'hf-near-market-grotto-entrance',
            'hf-cow-grotto-entrance',
            'hf-inside-fence-grotto-entrance',
            'hf-open-grotto-entrance',
            'hf-southeast-grotto-entrance',
            'lon-lon-ranch-entrance',
        ], 'hyrule-field'))
        // Lon Lon Ranch
        .concat(helper.starConnect([
            'lon-lon-ranch-tower-entrance',
            'lon-lon-ranch-entryway',
            'talons-house-entrance',
            'stables-entrance',
            'llr-grotto-entrance',
        ], 'lon-lon-ranch'))
        // Zora's River
        .concat(helper.starConnect([
            'zr-lost-woods-entrance',
            'zr-hyrule-field-entrance',
            'zr-zoras-domain-entrance',
            'zr-storms-grotto-entrance',
            'zr-fairy-grotto-entrance',
            'zr-open-grotto-entrance',
        ], 'zoras-river'))
        // Zora's Domain
        .concat(helper.starConnect([
            'zd-underwater-passage-entrance',
            'zd-zoras-river-entrance',
            'zd-storms-grotto-entrance',
            'zd-shop-entrance',
            'zd-zoras-fountain-entrance',
        ], 'zoras-domain'))
        // Zora's Fountain
        .concat(helper.starConnect([
            'zf-zoras-domain-entrance',
            'zf-great-fairy-fountain-entrance',
            'zf-ice-cavern-entrance',
            'zf-jabu-jabu-entrance',
        ], 'zoras-fountain'))
        // Ice Cavern
        .concat(helper.starConnect([
            'ice-cavern-entryway',
        ], 'ice-cavern'))
        // Jabu Jabu's Belly
        .concat(helper.starConnect([
            'zf-jabu-jabus-entryway',
            'jjb-boss-entrance',
        ], 'jabu-jabus-belly'))
        // Kakariko
        .concat(helper.starConnect([
            'kakariko-bazaar-entrance',
            'kakariko-shooting-gallery-entrance',
            'kakariko-potion-shop-entrance',
            'kakariko-potion-shop-back-entrance',
            'kakariko-hyrule-field-entrance',
            'kakariko-death-mountain-trail-entrance',
            'kakariko-open-grotto-entrance',
            'kakariko-redead-grotto-entrance',
            'bottom-of-the-well-entrance',
            'graveyard-entrance',
            'impas-house-entrance',
            'impas-house-back-entrance',
            'windmill-entrance',
            'kakariko-odd-potion-building-entrance',
            'kakariko-house-of-skulltulla-entrance',
            'kakariko-carpenter-boss-house-entrance',
        ], 'kakariko'))
        // Bottom of the Well
        .concat(helper.starConnect([
            'bottom-of-the-well-entryway',
        ], 'bottom-of-the-well'))
        // Graveyard
        .concat(helper.starConnect([
            'gy-kakariko-entrance',
            'gy-shield-grave-entrance',
            'gy-heart-piece-grave-entrance',
            'gy-composers-grave-entrance',
            'gy-dampes-grave-entrance',
            'gy-dampes-house-entrance',
        ], 'graveyard'))
        // Shadow Temple
        .concat(helper.starConnect([
            'shadow-temple-entryway',
            'st-boss-entrance',
        ], 'shadow-temple'))
        // Death Mountain Trail
        .concat(helper.starConnect([
            'dmt-kakariko-entrance',
            'dmt-goron-city-entrance',
            'dodongos-cavern-entrance',
            'dmt-cow-grotto-entrance',
            'dmt-storms-grotto-entrance',
            'dmt-death-mountain-crater-entrance',
            'dmt-great-fairy-fountain-entrance',
        ], 'death-mountain-trail'))
        // Dodongo's Caver
        .concat(helper.starConnect([
            'dodongos-cavern-entryway',
            'dc-boss-entrance',
        ], 'dodongos-cavern'))
        // Goron City
        .concat(helper.starConnect([
            'gc-death-mountain-trail-entrance',
            'gc-death-mountain-crater-entrance',
            'gc-grotto-entrance',
            'gc-shop-entrance',
            'gc-lost-woods-entrance',
        ], 'goron-city'))
        // Death Mountain Crater
        .concat(helper.starConnect([
            'dmc-goron-city-entrance',
            'dmc-hammer-grotto-entrance',
            'dmc-upper-grotto-entrance',
            'dmc-great-fairy-fountain-entrance',
            'fire-temple-entrance',
            'dmc-death-mountain-trail-entrance',
            'bolero-of-fire-warp-pad',
        ], 'death-mountain-crater'))
        // Fire Temple
        .concat(helper.starConnect([
            'fire-temple-entryway',
            'fit-boss-entrance',
        ], 'fire-temple'))
        // Market Entrance
        .concat(helper.starConnect([
            'me-hyrule-field-entrance',
            'me-market-entrance',
            'me-guard-house-entrance',
        ], 'market-entrance'))
        // Market
        .concat(helper.starConnect([
            'temple-of-time-garden-entrance',
            'hyrule-castle-entrance',
            'market-entryway',
            'market-bazaar-entrance',
            'market-shooting-gallery-entrance',
            'mask-shop-entrance',
            'bombchu-bowling-entrance',
            'man-in-green-house-entrance',
            'bombchu-shop-entrance',
            'treasure-chest-game-entrance',
            'potion-shop-entrance',
        ], 'market'))
        // Hyrule Castle
        .concat(helper.starConnect([
            'hc-great-fairy-fountain-entrance',
            'hyrule-castle-entryway',
            'hc-storms-grotto-entrance',
        ], 'hyrule-castle'))
        // Outside Ganon's Castle
        .concat(helper.starConnect([
            'hyrule-castle-entryway',
            'ogc-great-fairy-fountain-entrance',
            'ogc-ganons-castle-entrance',
        ], 'outside-ganons-castle'))
        // Ganon's Castle
        .concat(helper.starConnect([
            'ganons-castle-entryway',
        ], 'ganons-castle'))
        // Lake Hylia
        .concat(helper.starConnect([
            'lh-hyrule-field-entrance',
            'lh-underwater-passage-entrance',
            'lh-grotto-entrance',
            'lab-entrance',
            'fishing-hole-entrance',
            'water-temple-entrance',
            'serenade-of-water-warp-pad',
            'lh-river-entryway',
        ], 'lake-hylia'))
        // Water Temple
        .concat(helper.starConnect([
            'water-temple-entryway',
            'wt-boss-entrance',
        ], 'water-temple'))
        // Gerudo Valley
        .concat(helper.starConnect([
            'gv-hyrule-field-entrance',
            'gv-gerudo-fortress-entrance',
            'gv-storms-grotto-entrance',
            'gv-octorok-grotto-entrance',
            'gv-carpenter-tent-entrance',
            'gv-river-entrance',
        ], 'gerudo-valley'))
        // Gerudo Fortress
        .concat(helper.starConnect([
            'gf-wasteland-entrance',
            'gerudo-fortress-entryway',
            'gf-storms-grotto-entrance',
            'gf-gerudo-training-grounds-entrance',
        ], 'gerudo-fortress'))
        // Gerudo Training Grounds
        .concat(helper.starConnect([
            'gerudo-training-grounds-entryway',
        ], 'gerudo-training-grounds'))
        // Haunted Wasteland
        .concat(helper.starConnect([
            'wasteland-gerudo-fortress-entrance',
            'desert-colossus-entrance',
        ], 'haunted-wasteland'))
        // Desert Colossus
        .concat(helper.starConnect([
            'colossus-wasteland-entrance',
            'spirit-temple-entrance',
            'desert-colossus-great-fairy-fountain-entrance',
            'colossus-grotto-entrance',
            'requiem-of-spirit-warp-pad',
        ], 'desert-colossus'))
        // Spirit Temple
        .concat(helper.starConnect([
            'spirit-temple-entryway',
            'spt-boss-entrance',
        ], 'spirit-temple'))
        // Temple of Time
        .concat(helper.starConnect([
            'temple-of-time-entryway',
            'prelude-of-light-warp-pad',
        ], 'temple-of-time')),
    find(id) {
        return this.entrances.find(e => e.index === id) || {index: '', label: '', exit: ''};
    },
    translate(connection) {
        let source = this.find(connection.from);
        let destination = this.find(this.find(connection.to).exit);

        return {
            from: source.id,
            to: destination.id,
        }
    },
    graph(rewrites) {
        return {
            entrances: this.entrances,
            connections: this.entrances
                .map(e => rewrites.find(r => r.from === e.id) || {from: e.id, to: this.find(e.exit).id})
                .filter(c => c.from && c.to)
                .concat(this.connections)
                .map((c, index, arr) => {
                    if (c.invalid) return;

                    let bidirectional = arr.find(i => i.to === c.from && i.from === c.to && !i.bidirectional);

                    if (bidirectional) {
                        bidirectional.invalid = true;
                        c.bidirectional = true;
                    }

                    return c;
                })
                .filter(c => !!c)
                .filter(c => !c.invalid),
        };
    },
}