<template>
    <v-btn
        text
        @click="click"
        :color="color"
        width="125"
    >
        <v-icon left>
            {{ icon }}
        </v-icon>

        {{ title }}
    </v-btn>
</template>

<script>
export default {
    name: "SpoiledToggleButton",
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        color() {
            return this.value ? 'red' : 'green';
        },
        icon() {
            return this.value ? 'mdi-eye-outline' : 'mdi-eye-off-outline';
        },
    },
    methods: {
        click() {
            this.$emit('input', !this.value);
        },
    },
}
</script>

<style scoped>

</style>