<template>
    <div>
        <v-tooltip bottom :open-delay="500">
            <template v-slot:activator="{ on }">
                <v-btn
                    icon
                    @click="click"
                    :loading="loading"
                    :disabled="loading"
                    v-on="on"
                >
                    <v-icon>mdi-share-variant</v-icon>
                </v-btn>
            </template>

            Share log
        </v-tooltip>

        <v-snackbar
            top
            color="primary"
            v-model="info"
            :timeout="1500"
        >
            <v-layout d-flex justify-center>
                Link copied
            </v-layout>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: "SpoiledShareButton",
    data: function () {
        return {
            loading: false,
            info: false,
        };
    },
    methods: {
        click() {
            this.loading = true;

            this.$store.dispatch('Log/shareLog')
                .then(() => {
                    navigator.clipboard.writeText(location.href);
                    this.info = true;
                })
                .finally(() => this.loading = false);
        },
    },
}
</script>

<style scoped>

</style>