<template>
    <v-tooltip bottom :open-delay="500">
        <template v-slot:activator="{ on }">
            <v-btn
                icon
                @click="click"
                v-on="on"
            >
                <v-icon>mdi-undo-variant</v-icon>
            </v-btn>
        </template>

        Reset checks
    </v-tooltip>
</template>

<script>
export default {
    name: "SpoiledResetButton",
    methods: {
        click() {
            this.$store.dispatch('Log/reset');
        },
    },
}
</script>

<style scoped>

</style>