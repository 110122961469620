export default {
    starConnect(array, center) {
        let newArray = [];

        array.map(item => {
            newArray.push({from: item, to: center});
            newArray.push({from: center, to: item});
        });

        return newArray;
    },
}