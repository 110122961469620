<template>
    <v-select
        solo
        flat
        hide-details
        prepend-inner-icon="mdi-seed-outline"
        append-icon="mdi-chevron-down"
        label="Seed History"
        :items="history"
        item-text="id"
        return-object
        :value="id"
        @change="loadHistory($event)"
        :class="{ transparent: transparent }"
    >
        <template v-slot:selection="{ item }">
            <div>{{ item.seed | maxLength(10) }}</div>

            <v-spacer/>

            <div class="subtext text-no-wrap">{{ item.version }}</div>
        </template>

        <template v-slot:item="{ item }">
            <div class="mr-5">{{ item.seed }}</div>

            <v-spacer/>

            <div class="subtext mr-5 text-no-wrap">{{ item.version }}</div>
            <v-btn icon @click="deleteHistory(item)">
                <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
        </template>
    </v-select>
</template>

<script>
export default {
    name: "SpoiledHistory",
    props: {
        transparent: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        id() {
            return this.$store.getters['Log/id'];
        },
        history() {
            return this.$store.getters['Log/history'];
        },
    },
    methods: {
        loadHistory(log) {
            this.$store.commit('Log/load', log);
        },
        deleteHistory(log) {
            this.$store.dispatch('Log/deleteHistory', log);
        },
    },
}
</script>

<style scoped>
/deep/ input {
    display: none;
}

.transparent /deep/ .v-input__slot {
    background: transparent !important;
}
</style>