import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Settings from "@/store/settings";
import Log from "@/store/log";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Log: Log,
    Settings: Settings,
  },
  plugins: [createPersistedState()],
})
