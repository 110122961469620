export default {
    shops: [
        'GC Shop',
        'KF Shop',
        'Kak Bazaar',
        'Kak Potion Shop',
        'MK Bazaar',
        'MK Bombchu Shop',
        'MK Potion Shop',
        'ZD Shop',
    ],
    priceTable: [
        {id: 'Buy Recovery Heart', name: 'Recovery Heart', price: 10},

        {id: 'Buy Arrows (10)', name: 'Arrows (10)', price: 20},
        {id: 'Buy Arrows (30)', name: 'Arrows (30)', price: 60},
        {id: 'Buy Arrows (50)', name: 'Arrows (50)', price: 90},

        {id: 'Buy Deku Seeds (30)', name: 'Deku Seeds (30)', price: 30},

        {id: 'Buy Deku Nut (5)', name: 'Deku Nut (5)', price: 15},
        {id: 'Buy Deku Nut (10)', name: 'Deku Nut (10)', price: 30},

        {id: 'Buy Deku Stick (1)', name: 'Deku Stick (1)', price: 10},

        {id: 'Buy Deku Shield', name: 'Deku Shield', price: 40},
        {id: 'Buy Hylian Shield', name: 'Hylian Shield', price: 80},

        {id: 'Buy Bombs (5) [25]', name: 'Bombs (5)', price: 25},
        {id: 'Buy Bombs (5) [35]', name: 'Bombs (5)', price: 35},
        {id: 'Buy Bombs (10)', name: 'Bombs (10)', price: 50},
        {id: 'Buy Bombs (20)', name: 'Bombs (20)', price: 80},
        {id: 'Buy Bombs (30)', name: 'Bombs (40)', price: 120},

        {id: 'Buy Bombchu (10)', name: 'Bombchu (10)', price: 100},
        {id: 'Buy Bombchu (20)', name: 'Bombchu (20)', price: 180},

        {id: 'Buy Red Potion [30]', name: 'Red Potion', price: 30},
        {id: 'Buy Red Potion [40]', name: 'Red Potion', price: 40},
        {id: 'Buy Red Potion [50]', name: 'Red Potion', price: 50},
        {id: 'Buy Green Potion', name: 'Green Potion', price: 30},
        {id: 'Buy Blue Potion', name: 'Blue Potion', price: 100},

        {id: 'Buy Goron Tunic', name: 'Goron Tunic', price: 200},
        {id: 'Buy Zora Tunic', name: 'Zora Tunic', price: 300},

        {id: 'Buy Blue Fire', name: 'Blue Fire', price: 300},
        {id: 'Buy Bottle Bug', name: 'Bottle Bug', price: 50},
        {id: 'Buy Fairy\'s Spirit', name: 'Fairy\'s Spirit', price: 50},
        {id: 'Buy Fish', name: 'Fish', price: 200},
        {id: 'Buy Poe', name: 'Poe', price: 30},
    ],

    isShop(location) {
        return this.shops.some(l => location.includes(l));
    },
    find(item) {
        return this.priceTable.find(i => i.id === item) || {id: item, name: item, price: 0};
    },
}
