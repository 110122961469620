<template>
    <v-layout d-flex column>
        <spoiled-search v-model="search" class="mb-5"/>

        <v-card>
            <div class="pa-3">
                <v-data-table
                    :headers="headers"
                    :items="settings"
                    disable-pagination
                    hide-default-header
                    hide-default-footer
                    sort-by="category"
                    :search="search"
                >
                    <template v-slot:item.value="{ item }">
                        <v-icon color="green" v-if="['On', 'Yes', true].includes(item.value)">
                            mdi-check
                        </v-icon>
                        <v-icon color="red" v-else-if="['Off', 'No', false].includes(item.value)">
                            mdi-close
                        </v-icon>
                        <div v-else>{{ item.value }}</div>
                    </template>
                </v-data-table>
            </div>
        </v-card>
    </v-layout>
</template>

<script>
import SpoiledSearch from "@/components/base/SpoiledSearch.vue";

export default {
    name: "SpoiledSettings",
    components: {SpoiledSearch},
    data: function () {
        return {
            headers: [
                {text: 'Category', value: 'category'},
                {text: 'Name', value: 'name'},
                {text: 'Value', value: 'value', align: 'center'},
            ],
            search: null,
        };
    },
    computed: {
        settings() {
            return this.$store.getters['Log/settings'];
        },
    },
}
</script>

<style scoped>

</style>