<template>
    <v-card flat tile width="100%">
        <div id="map"/>

        <v-tooltip bottom :open-delay="500">
            <template v-slot:activator="{ on }">
                <v-btn absolute top left icon large @click="fit" v-on="on">
                    <v-icon>
                        mdi-fullscreen
                    </v-icon>
                </v-btn>
            </template>

            Fit to screen
        </v-tooltip>
    </v-card>
</template>

<script>
import Graph from "graphology";
import FA2Layout from 'graphology-layout-forceatlas2/worker';
import {bidirectional} from "graphology-shortest-path/dijkstra";
import {edgePathFromNodePath} from "graphology-shortest-path";

import Sigma from "sigma";
import getNodeImageProgram from "sigma/rendering/webgl/programs/node.image";

export default {
    name: "SpoiledMap",
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            graphSettings: {
                settings: {
                    gravity: 1,
                    adjustSizes: true,
                },
            },
            mapSettings: {
                allowInvalidContainer: true,
                minCameraRatio: 0.03,
                maxCameraRatio: 1,
                labelRenderedSizeThreshold: 10,

                nodeProgramClasses: {
                    image: getNodeImageProgram(),
                },
                defaultEdgeColor: 'grey',
                labelColor: {
                    color: 'dimgrey',
                },

                renderEdgeLabels: true,
            },

            sigma: null,
            graph: null,
            layout: null,
        };
    },
    computed: {
        entrances() {
            return [...this.value.entrances].sort((a, b) => a.label.localeCompare(b.label));
        },
    },
    methods: {
        init() {
            this.graph.clear();

            this.value.entrances.map(e => this.graph.addNode(e.id, {
                x: Math.random(),
                y: Math.random(),
                type: 'image', ...e
            }));
            this.value.connections.map(c => {
                c.bidirectional
                    ? this.graph.addUndirectedEdge(c.from, c.to, {size: 2, type: 'line'})
                    : this.graph.addDirectedEdge(c.from, c.to, {size: 2, type: 'arrow'});
            });
        },
        resetHighlight() {
            this.graph.updateEachNodeAttributes((node, attr) => {
                return {...attr, highlighted: false};
            });
        },
        fit() {
            this.map.getCamera().animatedReset({duration: 600});
        },
        focus(node) {
            this.fit();
            if (!node) return;

            this.graph.setNodeAttribute(node, 'highlighted', true);
            this.map.getCamera().animate({
                ...this.map.getNodeDisplayData(node),
                ratio: 0.1
            }, {duration: 600});
        },
        shortestPath(start, end) {
            if (!start || !end) return [];

            this.fit();

            let path = bidirectional(this.graph, start, end) ?? [];
            let edges = edgePathFromNodePath(this.graph, path) ?? [];

            this.graph.forEachEdge((edge, attributes) => {
                attributes.color = edges.includes(edge) ? 'lime' : this.mapSettings.defaultEdgeColor;
            });

            return path;
        },
        select(locations) {
            this.fit();
            this.resetHighlight();

            locations.map(l => {
                this.graph.setNodeAttribute(l, 'highlighted', true);
            })
        },
    },
    mounted() {
        this.graph = new Graph();

        this.layout = new FA2Layout(
            this.graph,
            this.graphSettings
        )
        this.layout.start();

        this.map = new Sigma(
            this.graph,
            document.getElementById("map"),
            this.mapSettings
        );

        this.init();
    },
    beforeDestroy() {
        this.graph.clear();
        this.layout.kill();
        this.map.kill();
    },
    watch: {
        entrances() {
            this.init();
        },
    },
}
</script>

<style scoped>
#map {
    width: 100%;
    height: 100%;
}
</style>