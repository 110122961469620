<template>
    <v-layout fill-height>
        <spoiled-bar>
            <v-tabs
                v-model="tab"
                align-with-title
                fixed-tabs
            >
                <v-tab href="#locations">
                    <v-icon class="mr-3">mdi-map-marker</v-icon>
                    Locations
                </v-tab>
                <v-tab href="#playthrough" :disabled="!hasPlaythrough">
                    <v-icon class="mr-3">mdi-book-open-page-variant-outline</v-icon>
                    Playthrough
                </v-tab>
                <v-tab href="#hints">
                    <v-icon class="mr-3">mdi-information-variant-circle-outline</v-icon>
                    Hints
                </v-tab>
                <v-tab href="#navigation">
                    <v-icon class="mr-3">mdi-map-marker-path</v-icon>
                    Navigation
                </v-tab>
                <v-tab href="#settings">
                    <v-icon class="mr-3">mdi-cogs</v-icon>
                    Settings
                </v-tab>
            </v-tabs>
        </spoiled-bar>

        <spoiled-bottom-bar>
            <spoiled-show-settings/>
        </spoiled-bottom-bar>

        <v-layout fill-height class="pa-3">
            <spoiled-locations :class="isHidden('locations')"/>
            <spoiled-playthrough :class="isHidden('playthrough')"/>
            <spoiled-hints :class="isHidden('hints')"/>
            <spoiled-navigation :class="isHidden('navigation')"/>
            <spoiled-settings :class="isHidden('settings')"/>
        </v-layout>
    </v-layout>
</template>

<script>
import SpoiledBar from "@/components/base/SpoiledBar";
import SpoiledLocations from "@/components/views/SpoiledLocations";
import SpoiledHints from "@/components/views/SpoiledHints";
import SpoiledPlaythrough from "@/components/views/SpoiledPlaythrough";
import SpoiledSettings from "@/components/views/SpoiledSettings";
import SpoiledBottomBar from "@/components/base/SpoiledBottomBar";
import SpoiledShowSettings from "@/components/settings/SpoiledShowSettings";
import SpoiledNavigation from "@/components/views/SpoiledNavigation.vue";

export default {
    name: "SpoiledLog",
    components: {
        SpoiledNavigation,
        SpoiledShowSettings,
        SpoiledBottomBar,
        SpoiledSettings,
        SpoiledPlaythrough,
        SpoiledHints,
        SpoiledLocations,
        SpoiledBar
    },
    data: function () {
        return {
            tab: 0,
        };
    },
    computed: {
        id() {
            return this.$store.getters['Log/id'];
        },
        hasPlaythrough() {
            return this.$store.getters['Log/playthrough'].length > 1;
        },
        hasHunt() {
            return this.$store.getters['Log/locations'].some(l => l.item === 'Triforce Piece');
        },
    },
    methods: {
        isHidden(id) {
            return this.tab !== id ? 'hidden' : '';
        },
    },
    watch: {
        id() {
            this.$vuetify.goTo(0, {duration: 0});
        },
        tab() {
            this.$vuetify.goTo(0, {duration: 0});
        },
    },
}
</script>

<style scoped>
.hidden {
    display: none !important;
}
</style>