export default {
    namespaced: true,
    state: {
        show: {
            spoilers: false,
            found: false,
            traps: false,
            prices: false,
        },
        dark: true,
    },
    getters: {
        showSpoilers: (state) => {
            return state.show.spoilers;
        },
        showFound: (state) => {
            return state.show.found;
        },
        showTraps: (state) => {
            return state.show.traps;
        },
        showPrices: (state) => {
            return state.show.prices;
        },

        darkMode: (state) => {
            return state.dark;
        },
    },
    mutations: {
        setSpoilers: (state, value) => {
            state.show.spoilers = value;
        },
        setFound: (state, value) => {
            state.show.found = value;
        },
        setTraps: (state, value) => {
            state.show.traps = value;
        },
        setPrices: (state, value) => {
            state.show.prices = value;
        },

        reset: (state) => {
            state.show.spoilers = false;
            state.show.found = false;
            state.show.traps = false;
            state.show.prices = false;
        },

        setDarkMode: (state, value) => {
            state.dark = value;
        },
    },
    actions: {
        reset: (context) => {
            return context.commit('reset');
        },
    },
}