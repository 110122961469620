<template>
    <v-layout d-flex align-center>
        <v-autocomplete solo hide-details label="Origin" prepend-inner-icon="mdi-airplane-takeoff" :items="entrances" item-text="label" item-value="id" v-model="start"/>
        <v-btn icon x-large @click="route" :disabled="!isValidRoute" class="mx-5">
            <v-icon>mdi-map-marker-path</v-icon>
        </v-btn>
        <v-autocomplete solo hide-details label="Destination" prepend-inner-icon="mdi-airplane-landing" :items="entrances" item-text="label" item-value="id" v-model="end"/>
    </v-layout>
</template>

<script>
import Entrances from "@/helper/entrances";

export default {
    name: "SpoiledRouteSelector",
    data: function() {
        return {
            start: null,
            end: null,
        };
    },
    computed: {
        entrances() {
            return [...Entrances.entrances].sort((a, b) => a.label.localeCompare(b.label));
        },
        isValidRoute() {
            return !!this.start && !!this.end && this.start !== this.end;
        },
    },
    methods: {
        route() {
            this.$emit('route', {
                start: this.start,
                end: this.end,
            });
        },
        reset() {
            this.start = null;
            this.end = null;
            this.route();
        },
    },
}
</script>

<style scoped>

</style>