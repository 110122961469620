<template>
    <v-btn icon @click="toTop">
        <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
</template>

<script>
export default {
    name: "SpoiledToTop",
    methods: {
        toTop() {
            this.$vuetify.goTo(0);
        },
    },
}
</script>

<style scoped>

</style>